
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout'
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import { toastify } from '../../../utils/toast';
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';
import { getUnitsForFacility } from '../../../utils/urls';

function UnitManagement() {
    

    const [units, setUnits] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // Fetch units for the selected facility
    const fetchUnitsForFacility = async () => {
        try {
            const value = await getItem('selectedFacilityId')
            const response = await makeRequest2(`${getUnitsForFacility}/${value}`, 'GET', {});
            console.log("Response", response)

            if (response.success) {
                setUnits(response.data); // Set fetched units to state

            } else {
                throw new Error('Failed to fetch units')
            }
        } catch (error) {
            console.log(error.message)
            toastify(error.message, 'error');
        }
    };
    
    const handleInputSearch = async (value) => {
        try {
            setSearchTerm(value);
            if (value === '') {
                fetchUnitsForFacility()
            } else {
                const results = await filterObjectsByAllProperties(units, value);
                setUnits(results);
            }
        } catch (error) {
            toastify(error.message, 'error');
        }
    };

    const handleUnitClick = (rowData) => {
        return (
            <Link to={'/facility/unit_management/view_unit/' + rowData._id} style={{ color: 'royalblue' }}>
                {rowData.name}
            </Link>
        )
    }


    useEffect(() => {
        fetchUnitsForFacility();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/unit_management"}>Unit Management</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Units</h5>

                                </div>
                                <div className='card-body'>
                                    <div className="row">

                                        <div className="col-lg-12 col-xxl-12">
                                            <div className="card">
                                                <div className="card-body position-relative">
                                                    <div className="row">
                                                        <div className='col-md-4'>
                                                            <div className="form-search">
                                                                <i className="ti ti-search"></i>
                                                                <input type="search" className="form-control" placeholder="Search Here"
                                                                    value={searchTerm}
                                                                    onChange={(e) => {
                                                                        handleInputSearch(e.target.value)
                                                                    }} 
                                                                    />
                                                            </div>
                                                        </div>


                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="dt-responsive table-responsive mt-4">
                                                                <DataTable value={units} emptyMessage="No Units found." sortMode="multiple" paginator rows={10} stripedRows tableStyle={{ minWidth: '50rem' }}>

                                                                    <Column header="Name" 
                                                                    body={handleUnitClick}
                                                                    ></Column>
                                                                    <Column field="unitType" header="Type"></Column>
                                                                    <Column field="division" header="Division"></Column>
                                                                    <Column field="floorUnitNo" header="Floor/Unit"></Column>
                                                                    <Column field="lettableFloorArea" header="LFA"></Column>
                                                                    <Column field="landRateNumber" header="LRN"></Column>
                                                                    <Column field="grossArea" header="GA"></Column>
                                                                    <Column field="netLettableArea" header="NLA"></Column>

                                                                </DataTable>


                                                            </div>
                                                        </div>

                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}


export default UnitManagement