
import React from 'react'
import { DataTable } from 'primereact/datatable';
import { Link } from 'react-router-dom'
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout'
function TicketManagement() {


    // const header = (
    //     <div className="flex align-items-center justify-content-end gap-2">
    //         <Button type="button" label="CSV" rounded onClick={() => { }} data-pr-tooltip="CSV" />
    //         <Button type="button" label="XLS" severity="success" rounded onClick={() => { }} data-pr-tooltip="XLS" />
    //         <Button type="button" label="PDF" severity="warning" rounded onClick={() =add> { }} data-pr-tooltip="PDF" />
    //     </div>
    // );
    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/app/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/app/ticket_management"}>Ticket Management</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">


                    <div className="row">

                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="card">
                                                <div className="card-body py-0">
                                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="profile-tab-1" data-bs-toggle="tab" href="#profile-1" role="tab" aria-selected="true">
                                                                <i className="ti ti-columns me-2"></i>Summary
                                                            </a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-2" data-bs-toggle="tab" href="#profile-2" role="tab" aria-selected="true">
                                                                <i className="ti ti-file-text me-2"></i>Tickets
                                                            </a>
                                                        </li>

                                                        <li className="nav-item">
                                                            <a className="nav-link" id="profile-tab-4" data-bs-toggle="tab" href="#profile-3" role="tab" aria-selected="true">
                                                                <i className="ti ti-color-swatch me-2"></i>Ticket Types
                                                            </a>
                                                        </li>
                                                       


                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="tab-content">
                                                <div className="tab-pane show active" id="profile-1" role="tabpanel" aria-labelledby="profile-tab-1">
                                                    <div className="row">
                                                        <div className="col-lg-4 col-xxl-4">
                                                            <div className="card">
                                                                <div className="card-body position-relative">

                                                                    <div className="text-center mt-3">

                                                                        <h1 className="mb-0">300</h1>
                                                                        <p className="text-muted text-sm">Tickets</p>
                                                                        <hr className="my-3 border border-secondary-subtle" />
                                                                        <div className="row g-3">
                                                                            <div className="col-4">
                                                                                <h5 className="mb-0">100</h5>
                                                                                <small className="text-muted">Open</small>
                                                                            </div>
                                                                            <div className="col-4 border border-top-0 border-bottom-0">
                                                                                <h5 className="mb-0">50</h5>
                                                                                <small className="text-muted">Ongoing</small>
                                                                            </div>
                                                                            <div className="col-4">
                                                                                <h5 className="mb-0">150</h5>
                                                                                <small className="text-muted">Closed</small>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-3 border border-secondary-subtle" />
                                                                        <div className="row g-3">
                                                                            <div className="col-6">
                                                                                <h5 className="mb-0">24 hr</h5>
                                                                                <small className="text-muted">Resolution Time</small>
                                                                            </div>
                                                                            <div className="col-6 border border-top-0 border-bottom-0">
                                                                                <h5 className="mb-0">50 mins</h5>
                                                                                <small className="text-muted">TNT</small>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-lg-8 col-xxl-8">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <div className="d-flex align-items-center justify-content-between">
                                                                        <h5 className="mb-0">Summary of Issues</h5>

                                                                    </div>
                                                                    <div className="row align-items-center justify-content-center">
                                                                        <div className="col-md-6 col-xl-4">
                                                                            <div className="mt-3 row align-items-center">
                                                                                <div className="col-6">
                                                                                    <p className="text-muted mb-1">Maintenance</p>
                                                                                    <h5 className="mb-0">120</h5>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-4">
                                                                            <div className="mt-3 row align-items-center">
                                                                                <div className="col-6">
                                                                                    <p className="text-muted mb-1">Complaints</p>
                                                                                    <h5 className="mb-0">50</h5>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6 col-xl-4">
                                                                            <div className="mt-3 row align-items-center">
                                                                                <div className="col-6">
                                                                                    <p className="text-muted mb-1">Services</p>
                                                                                    <h5 className="mb-0">130</h5>
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="tab-pane" id="profile-2" role="tabpanel" aria-labelledby="profile-tab-2">
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <div className="card">
                                                                <div className="card-body">
                                                                    <ul className="nav nav-tabs invoice-tab border-bottom mb-3" id="myTab" role="tablist">
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link active" id="analytics-tab-1" data-bs-toggle="tab"
                                                                                data-bs-target="#analytics-tab-1-pane" type="button" role="tab" aria-controls="analytics-tab-1-pane"
                                                                                aria-selected="true">
                                                                                <span className="d-flex align-items-center gap-2">All <span
                                                                                    className="avtar rounded-circle bg-light-primary">0</span></span>
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link" id="analytics-tab-2" data-bs-toggle="tab"
                                                                                data-bs-target="#analytics-tab-2-pane" type="button" role="tab" aria-controls="analytics-tab-2-pane"
                                                                                aria-selected="false">
                                                                                <span className="d-flex align-items-center gap-2">Open <span
                                                                                    className="avtar rounded-circle bg-light-success">0</span></span>
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link" id="analytics-tab-3" data-bs-toggle="tab"
                                                                                data-bs-target="#analytics-tab-3-pane" type="button" role="tab" aria-controls="analytics-tab-3-pane"
                                                                                aria-selected="false">
                                                                                <span className="d-flex align-items-center gap-2">Ongoing <span
                                                                                    className="avtar rounded-circle bg-light-warning">0</span></span>
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-item" role="presentation">
                                                                            <button className="nav-link" id="analytics-tab-4" data-bs-toggle="tab"
                                                                                data-bs-target="#analytics-tab-4-pane" type="button" role="tab" aria-controls="analytics-tab-4-pane"
                                                                                aria-selected="false">
                                                                                <span className="d-flex align-items-center gap-2">Resolved <span
                                                                                    className="avtar rounded-circle bg-light-danger">0</span></span>
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="tab-content" id="myTabContent">
                                                                        <div className="tab-pane fade show active mt-2" id="analytics-tab-1-pane" role="tabpanel"
                                                                            aria-labelledby="analytics-tab-1" tabindex="0">
                                                                            <div className="table-responsive">
                                                                                <DataTable value={[]} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                                    <Column field="ticketId" header="Ticket ID"></Column>
                                                                                    <Column field="customer" header="Customer"></Column>
                                                                                    <Column field="project" header="Project"></Column>
                                                                                    <Column field="subject" header="Subject"></Column>
                                                                                    <Column field="description" header="Description"></Column>
                                                                                    <Column field="status" header="Status"></Column>
                                                                                    <Column field="date" header="Added On"></Column>


                                                                                </DataTable>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-pane fade mt-2" id="analytics-tab-2-pane" role="tabpanel"
                                                                            aria-labelledby="analytics-tab-2" tabindex="0">
                                                                            <div className="table-responsive">
                                                                                <DataTable value={[]} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                                    <Column field="ticketId" header="Ticket ID"></Column>
                                                                                    <Column field="customer" header="Customer"></Column>
                                                                                    <Column field="project" header="Project"></Column>
                                                                                    <Column field="subject" header="Subject"></Column>
                                                                                    <Column field="description" header="Description"></Column>
                                                                                    <Column field="date" header="Added On"></Column>
                                                                                </DataTable>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-pane fade mt-2" id="analytics-tab-3-pane" role="tabpanel"
                                                                            aria-labelledby="analytics-tab-3" tabindex="0">
                                                                            <div className="table-responsive">
                                                                                <DataTable value={[]} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                                    <Column field="ticketId" header="Ticket ID"></Column>
                                                                                    <Column field="customer" header="Customer"></Column>
                                                                                    <Column field="project" header="Project"></Column>
                                                                                    <Column field="subject" header="Subject"></Column>
                                                                                    <Column field="description" header="Description"></Column>
                                                                                    <Column field="date" header="Added On"></Column>


                                                                                </DataTable>
                                                                            </div>
                                                                        </div>
                                                                        <div className="tab-pane fade mt-2" id="analytics-tab-4-pane" role="tabpanel"
                                                                            aria-labelledby="analytics-tab-4" tabindex="0">
                                                                            <div className="table-responsive">
                                                                                <DataTable value={[]} emptyMessage="No tickets found." sortMode="multiple" paginator rows={5} stripedRows tableStyle={{ minWidth: '50rem' }}>
                                                                                    <Column field="ticketId" header="Ticket ID"></Column>
                                                                                    <Column field="customer" header="Customer"></Column>
                                                                                    <Column field="project" header="Project"></Column>
                                                                                    <Column field="subject" header="Subject"></Column>
                                                                                    <Column field="description" header="Description"></Column>
                                                                                    <Column field="date" header="Added On"></Column>
                                                                                    <Column field="date" header="Resolved On"></Column>


                                                                                </DataTable>
                                                                            </div>
                                                                        </div>



                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                <div className="tab-pane" id="profile-3" role="tabpanel" aria-labelledby="profile-tab-4">
                                                    <div className="card">
                                                      
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Name</label>
                                                                        <input type="text" className="form-control" />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Type</label>
                                                                        <select type="text" className="form-control" >
                                                                            <option>Maintenance</option>
                                                                            <option>Service</option>
                                                                            <option>Complaints</option>
                                                                            </select>
                                                                    </div>
                                                                    <div className=" text-end btn-page">
                                                            <div className="btn btn-primary">Add</div>
                                                        </div>
                                                                   
                                                                </div>
                                                                <div className="col-sm-6">
                                                                 
                                                                    <ul className="list-group list-group-flush">
                                                                        <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> Noise Levels ( Complaints )<i className="ti ti-trash" style={{float:'right',color:'red'}}></i></li>
                                                                        <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> Clealiness ( Complaints )<i className="ti ti-trash" style={{float:'right',color:'red'}}></i></li>
                                                                        <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> Neighbour issue ( Complaints )<i className="ti ti-trash" style={{float:'right',color:'red'}}></i></li >
                                                                        <li className="list-group-item"><i className="ti ti-circle-check text-success f-16 me-2"></i> Parking ( Complaints )<i className="ti ti-trash" style={{float:'right',color:'red'}}></i></li>
                                                                        <li className="list-group-item" ><i className="ti ti-circle-check text-success f-16 me-2"></i> Infestation ( Complaints )<i className="ti ti-trash" style={{float:'right',color:'red'}}></i></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

        </Layout>
    )
}


export default TicketManagement