import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import Layout from '../component/layout';
import { getItem } from '../../../utils/localStorage';
import { toastify } from '../../../utils/toast';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getServices, addNewService } from '../../../utils/urls';

function Services() {
    const navigate = useNavigate();

    // State for Services
    const [serviceName, setServiceName] = useState('');
    const [services, setServices] = useState([]);

    // State for Work Orders
    const [workOrders, setWorkOrders] = useState([]);
    const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

    // Dialog visibility states
    const [isServiceDialogVisible, setServiceDialogVisible] = useState(false);
    const [isWorkOrderDetailsDialogVisible, setWorkOrderDetailsDialogVisible] = useState(false);

    // Fetch Services
    const fetchServices = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const url = getServices + '/' + facilityId;
            const response = await makeRequest2(url, 'GET', {});

            if (response.success) {
                setServices(response.data);
            } else {
                toastify(response.error, 'error');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    // Add Service
    const handleAddService = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');

            const url = addNewService + '/' + facilityId;

            const response = await makeRequest2(url, 'POST', {
                name: serviceName,
                facilityId
            });

            if (response.success) {
                toastify('Service added successfully', 'success');
                setServiceName('');
                fetchServices();
                setServiceDialogVisible(false);
            } else {
                toastify(response.error, 'error');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    // Fetch Work Orders
    const fetchWorkOrders = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            // const response = await makeRequest2(`/api/work-orders/${facilityId}`, 'GET');
            //Refactor code

            // if (response.success) {
            //     setWorkOrders(response.data);
            // } else {
            //     toastify(response.error, 'error');
            // }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    // Work Order Status Template
    const statusTemplate = (rowData) => {
        const statusColors = {
            'staged': '#FFA500',      // Orange
            'started': '#4CAF50',     // Green
            'pending': '#2196F3',     // Blue
            'in-review': '#9C27B0'    // Purple
        };

        return (
            <span
                style={{
                    backgroundColor: statusColors[rowData.status] || '#grey',
                    color: 'white',
                    padding: '5px 10px',
                    borderRadius: '15px'
                }}
            >
                {rowData.status}
            </span>
        );
    };

    // View Work Order Details
    const handleViewWorkOrder = (workOrder) => {
        setSelectedWorkOrder(workOrder);
        setWorkOrderDetailsDialogVisible(true);
    };

    // Actions Column for Work Orders
    const workOrderActionTemplate = (rowData) => (
        <div className="actions">
            <Button
                icon="ti ti-eye"
                className="p-button-rounded p-button-info mr-2"
                onClick={() => handleViewWorkOrder(rowData)}
                tooltip="View Details"
            />
        </div>
    );

    useEffect(() => {
        fetchServices();
        fetchWorkOrders();
    }, []);

    return (
        <Layout>
            <div className="page-header">
                <div className="breadcrumb">
                    <Link to={"/app/"}>Dashboard</Link>
                    <Link to={""}>Maintenance Management</Link>
                </div>
            </div>

            <div className="card">
                <div className="card-body py-2">
                    <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" data-bs-toggle="tab" href="#work-orders">
                                <i className="ti ti-list me-2"></i>Work Orders
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" data-bs-toggle="tab" href="#services">
                                <i className="ti ti-settings me-2"></i>Services
                            </a>
                        </li>
                    </ul>
                </div>
            </div>

            <div className="tab-content">
                {/* Work Orders Tab */}
                <div className="tab-pane show active" id="work-orders">
                    <div className="card">
                        <div className="card-header">
                            <h5>Work Orders</h5>
                        </div>
                        <div className="card-body">
                            <DataTable
                                value={workOrders}
                                emptyMessage="No work orders found"
                                paginator
                                rows={5}
                                sortMode="multiple"
                                stripedRows
                            >
                                <Column field="serviceName" header="Service" />
                                <Column field="when" header="Scheduled Date"
                                    body={(rowData) => new Date(rowData.when).toLocaleDateString()}
                                />
                                <Column field="pricing" header="Price"
                                    body={(rowData) => `$${rowData.pricing}`}
                                />
                                <Column body={statusTemplate} header="Status" />
                                <Column body={workOrderActionTemplate} header="Actions" />
                            </DataTable>
                        </div>
                    </div>
                </div>

                {/* Services Tab */}
                <div className="tab-pane fade" id="services">
                    <div className="card">
                        <div className="card-header">
                            <h5>Maintenance Services</h5>
                            <Button
                                label="Add Service"
                                icon="ti ti-plus"
                                onClick={() => setServiceDialogVisible(true)}
                            />
                        </div>
                        <div className="card-body">
                            <ul className="list-group">
                                {services.map((service) => (
                                    <li key={service._id} className="list-group-item">
                                        {service.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Service Dialog */}
            <Dialog
                header="Add New Service"
                visible={isServiceDialogVisible}
                onHide={() => setServiceDialogVisible(false)}
            >
                <div className="p-fluid">
                    <div className="p-field">
                        <label>Service Name</label>
                        <InputText
                            value={serviceName}
                            onChange={(e) => setServiceName(e.target.value)}
                            placeholder="Enter service name"
                        />
                    </div>
                    <Button
                        label="Add Service"
                        onClick={handleAddService}
                        className="mt-3"
                    />
                </div>
            </Dialog>

            {/* Work Order Details Dialog */}
            <Dialog
                header="Work Order Details"
                visible={isWorkOrderDetailsDialogVisible}
                onHide={() => setWorkOrderDetailsDialogVisible(false)}
                style={{ width: '50vw' }}
            >
                {selectedWorkOrder && (
                    <div>
                        <p><strong>Service:</strong> {selectedWorkOrder.serviceName}</p>
                        <p><strong>Scheduled Date:</strong> {new Date(selectedWorkOrder.when).toLocaleString()}</p>
                        <p><strong>Pricing:</strong> ${selectedWorkOrder.pricing}</p>
                        <p><strong>Status:</strong> {selectedWorkOrder.status}</p>
                    </div>
                )}
            </Dialog>
        </Layout>
    );
}

export default Services;

