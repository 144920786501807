import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import 'primereact/resources/themes/lara-light-blue/theme.css';
import Layout from '../component/layout';
import { toastify } from '../../../utils/toast';
import { getItem } from '../../../utils/localStorage';
import { makeRequest2 } from '../../../utils/makeRequest';
import { getCustomersURL } from '../../../utils/urls';
import { filterObjectsByAllProperties } from '../../../utils/filterSearch';

function CustomerManagement() {
  const navigate = useNavigate()

  const [customers, setCustomers] = useState([])
  
  const fetchCustomersForFacility = async () => {
    try {
      const value = await getItem('selectedFacilityId');
      const response = await makeRequest2(`${getCustomersURL}/${value}`, 'GET', {});
      if (response.success) {
        // Sort customers by createdAt in descending order
        const sortedCustomers = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setCustomers(sortedCustomers);
      } else {
        throw new Error('Failed to fetch customers');
      }
    } catch (error) {
      console.log(error.message);
      toastify(error.message, 'error');
    }
  };

  const capitalize = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  }

  const handleCustomerClick = (rowData) => {
    const fullName = rowData.fullName || `${capitalize(rowData.firstName)} ${capitalize(rowData.lastName)}`;

    return (
      <Link to={'/facility/customer_management/view_customer/' + rowData._id} style={{ color: 'royalblue' }}>
        {fullName}
      </Link>
    );
  };

  const phoneNumberTemplate = (rowData) => {
    const phoneNumber = rowData.phoneNumber;
    return '+254' + phoneNumber
  }

  useEffect(()=>{
    fetchCustomersForFacility()
  },[])

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/facility/customer_management"}>Member Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h5>Members</h5>
                </div>
                <div className='card-body'>
                  <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                      <div className="card">
                        <div className="card-body position-relative">
                          <div className="row">
                            <div className="col-md-3 col-xs-12 mb-3">
                              <input
                                className="form-control"
                                placeholder="Search here"
                                // value={searchTerm}
                                // onChange={(e) => { handleInputSearch(e.target.value) }}
                              />
                            </div>
                            
                            <div className="col-md-9 col-xs-12 mb-3">
                              <div style={{ float: 'right' }}>
                                <button className='btn btn-primary' onClick={() => navigate('/facility/customer_management/add_new_customer')}>Add New Member</button>
                                &nbsp;
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="dt-responsive table-responsive">
                                <DataTable
                                  value={customers}
                                  emptyMessage="No customers found."
                                  sortMode="multiple"
                                  paginator
                                  rows={10}
                                  stripedRows
                                  tableStyle={{ minWidth: '50rem' }}
                                >
                                  <Column field="customerNumber" header='No' />
                                  <Column header="Full Name" body={handleCustomerClick}/>
                                  <Column field="email" header="Email" />
                                  <Column  header="Phone" body={phoneNumberTemplate}/>
                                  <Column field="idNumber" header="ID No / PP No" />
                                  <Column field="customerType" header="Customer Type" />
                                  <Column field="residentType" header="Resident Type" />
                                </DataTable>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CustomerManagement;
