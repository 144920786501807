import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { usePenaltyForm } from '../hooks/usePenaltyForm';

const PenaltyForm = ({ levies, module, onSubmitSuccess }) => {
  const {
    type,
    selectedLevyId,
    penaltyData,
    handlePenaltySubmit,
    handleLevyChange,
    handleTypeChange,
    handleInputChange
  } = usePenaltyForm(levies, module, onSubmitSuccess);

  return (
    <form onSubmit={handlePenaltySubmit}>
      <input type="hidden" name="module" value={module} />

      <div className="form-group mb-4">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={penaltyData.name}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group mb-4">
        <label htmlFor="levy">Select Levy</label>
        <Dropdown
          value={selectedLevyId}
          options={levies.map(levy => ({ label: levy.levyName, value: levy._id }))}
          onChange={(e) => {
            const selectedLevy = levies.find(levy => levy._id === e.value);
            handleLevyChange(selectedLevy);
          }}
          placeholder="Select a levy"
          className="w-100"
          required
        />
      </div>

      <div className="form-group mb-4">
        <label htmlFor="type">Type</label>
        <Dropdown
          value={type}
          options={[
            { label: "Percentage", value: "percentage" },
            { label: "Fixed Amount", value: "fixed" }
          ]}
          onChange={(e) => handleTypeChange(e)}
          placeholder="Select a type"
          className="w-100"
          required
        />
      </div>

      {type === "percentage" && (
        <div className="form-group mb-4">
          <label htmlFor="percentage">Percentage</label>
          <input
            type="number"
            className="form-control"
            id="percentage"
            name="percentage"
            value={penaltyData.percentage || ''}
            onChange={handleInputChange}
            required
          />
        </div>
      )}

      {type === "fixed" && (
        <div className="form-group mb-4">
          <label htmlFor="amount">Amount</label>
          <input
            type="number"
            className="form-control"
            id="amount"
            name="amount"
            value={penaltyData.amount}
            onChange={handleInputChange}
            required
          />
        </div>
      )}

      <div className="form-group mb-4">
        <label htmlFor="effectDays">Effect Days</label>
        <input
          type="number"
          className="form-control"
          id="effectDays"
          name="effectDays"
          value={penaltyData.effectDays}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group mb-4">
        <label>Is Active</label>
        <div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="isActive"
              name="isActive"
              checked={penaltyData.isActive}
              onChange={(e) => handleInputChange({ target: { name: 'isActive', value: e.target.checked } })}
            />
            <label className="form-check-label" htmlFor="isActive">
              Active
            </label>
          </div>
        </div>
      </div>

      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </form>
  );
};

export default PenaltyForm;