import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact';
import { toastify } from '../../../../utils/toast';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { getItem } from '../../../../utils/localStorage';
import { getContracts, editContract } from '../../../../utils/urls'; // Assuming the endpoint is imported here
import { Button } from 'primereact/button'; // Assuming the Button component is used

const EditContract = ({ contractId, onClose, fetchContracts }) => {
    const [contractName, setContractName] = useState('');
    const [amount, setAmount] = useState('');
    const [collectionFrequency, setCollectionFrequency] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (contractId) {
            fetchContractDetails();
        }
    }, [contractId]);

    // Fetch contract details for editing
    const fetchContractDetails = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            const response = await makeRequest2(getContracts + '/' + facilityId, 'GET');
            if (response.success) {
                const { contractName, amount, collectionFrequency, startDate, endDate } = response.data;

                // Ensure startDate and endDate are valid before trying to format
                const formattedStartDate = startDate ? startDate.split('/').reverse().join('-') : '';
                const formattedEndDate = endDate ? endDate.split('/').reverse().join('-') : '';

                setContractName(contractName);
                setAmount(amount);
                setCollectionFrequency(collectionFrequency);
                setStartDate(formattedStartDate);
                setEndDate(formattedEndDate);
            } else {
                throw new Error(response.error || 'Failed to fetch contract details.');
            }
        } catch (err) {
            toastify(err.message, 'error');
        }
    };

    // Handle the submit action to update the contract
    const handleEditSubmit = async () => {
        if (!contractName || !amount || !collectionFrequency || !startDate || !endDate) {
            toastify('All fields are required', 'error');
            return;
        }

        setLoading(true);
        try {
            const facilityId = await getItem('selectedFacilityId');
            const updatedData = {
                contractName,
                amount: parseFloat(amount),
                collectionFrequency,
                startDate,
                endDate,
            };

            // Use the correct URL for the PUT request to update the contract
            const response = await makeRequest2(editContract + '/' + facilityId + '/' + contractId, 'PUT', updatedData);

            if (response.success) {
                toastify('Contract updated successfully!', 'success');
                fetchContracts(); // Refresh the contract list after update (call parent method)
                onClose(); // Close the modal after successful update
            } else {
                throw new Error(response.error || 'Failed to update contract.');
            }
        } catch (err) {
            toastify(err.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="dialog-body">
                <div className="form-group mb-4">
                    <label>Contract Name:</label>
                    <InputText
                        value={contractName}
                        className="form-control mt-2"
                        placeholder="Enter contract name"
                        onChange={(e) => setContractName(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4">
                    <label>Amount:</label>
                    <InputText
                        value={amount}
                        className="form-control mt-2"
                        placeholder="Enter amount"
                        type="number"
                        onChange={(e) => setAmount(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4">
                    <label>Collection Frequency:</label>
                    <select
                        value={collectionFrequency}
                        className="form-control mt-2"
                        onChange={(e) => setCollectionFrequency(e.target.value)}
                    >
                        <option value="">Select Frequency</option>
                        <option value="Weekly">Weekly</option>
                        <option value="Monthly">Monthly</option>
                        <option value="Quarterly">Quarterly</option>
                        <option value="Semi-annually">Semi-annually</option>
                        <option value="Annually">Annually</option>
                    </select>
                </div>
                <div className="form-group mb-4">
                    <label>Start Date:</label>
                    <InputText
                        type="date"
                        className="form-control mt-2"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                </div>
                <div className="form-group mb-4">
                    <label>End Date:</label>
                    <InputText
                        type="date"
                        className="form-control mt-2"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                </div>
            </div>

            <div className="dialog-footer d-flex justify-content-end">
                {/* Cancel Button */}
                <Button
                    className="p-button p-button-secondary p-button-rounded me-2"
                    onClick={onClose}  // Close the dialog
                >
                     Cancel
                </Button>

                {/* Submit Button */}
                <Button
                    className="p-button p-button-primary p-button-rounded"
                    onClick={handleEditSubmit}
                    disabled={loading}
                >
                    {loading ? 'Updating...' : 'Submit'}
                </Button>
            </div>
        </div>
    );
};

export default EditContract;
