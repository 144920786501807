import { Link, useNavigate } from "react-router-dom";
import Layout from "../component/layout";
function AddNewFacility() {
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/app/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/app/facilities"}>Facilities</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Add New Facility</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="row">
            <div className="col-md-12">
              <Link to="/app/facilities">
                <i className="ti ti-arrow-left"></i>
                &nbsp; Back
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <label>Facility Name:</label>
              <input type="text" className="form-control mt-2" />
            </div>
            <div className="col-md-4">
              <label>Location:</label>
              <input type="text" className="form-control mt-2" />
            </div>
            <div className="col-md-4">
              <label>Facility Type:</label>
              <select className="form-control mt-2">
                <option>-- Select --</option>
                <option>Estate</option>
                <option>Complex</option>
              </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              <label>Number of Blocks:</label>
              <input type="number" className="form-control mt-2" />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-6">
              <label>Block Name:</label>
              <input type="text" className="form-control mt-2" />
            </div>
            <div className="col-md-6">
              <label>Number of Floors:</label>
              <input type="number" className="form-control mt-2" />
            </div>
          </div>
        </div>
        <div className="card-footer">
          <div className="row">
            <div className="col-md-12">
              <div style={{ float: "right" }}>
                <button className="btn btn-primary">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default AddNewFacility;
