import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Card } from "primereact/card";

import Layout from "../../component/layout";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { getItem } from "localforage";
import { useFacilityStore } from "../../../../app/z-store/store";

const StockAndSpareManagement = () => {
  const [stocks, setStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedStock, setSelectedStock] = useState(null);
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);
  const [formData, setFormData] = useState({
    name: "",
    description: "", 
    skuDetails: "",
    quantityInStock: "",
    reOrderLevel: "",
  });  
  const [stockStats, setStockStats] = useState({
    totalItems: 0,
    lowStockItems: 0,
    averageStock: 0,
  });
  const toast = useRef(null);

  useEffect(() => {
    // Don't need to set facilityId here since it's already managed by the store
    if (selectedFacilityId) {
      fetchStocks(selectedFacilityId);
      console.log("facilityId", selectedFacilityId);
    }
  }, [selectedFacilityId]); // Add selectedFacilityId as dependency

  const calculateStats = () => {
    const totalItems = stocks.length;
    const lowStockItems = stocks.filter(
      (stock) => stock.quantityInStock <= stock.reOrderLevel
    ).length;
    const averageStock =
      stocks.reduce((sum, stock) => sum + stock.quantityInStock, 0) /
      totalItems;

    setStockStats({
      totalItems,
      lowStockItems,
      averageStock: Math.round(averageStock),
    });
  };

  const fetchStocks = async (currentFacilityId) => {
    try {
      setLoading(true);
      const idToUse = currentFacilityId || selectedFacilityId;

      if (!idToUse) {
        throw new Error("No facility ID available");
      }

      const response = await makeRequest2(
        `/api/stockandspare/${idToUse}`,
        "GET",
        null
      );

      if (response.success) {
        const stockData = Array.isArray(response.data)
          ? response.data
          : Array.isArray(response.data.stocks)
          ? response.data.stocks
          : [];
        setStocks(stockData);
        setError(null);
      } else {
        setError(response.error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error,
        });
      }
    } catch (err) {
      setError("Failed to fetch stocks");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err.message || "Failed to fetch stocks",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stocks && stocks.length > 0) {
      calculateStats();
    }
  }, [stocks]);

  const filteredStocks = Array.isArray(stocks)
    ? stocks.filter((stock) =>
        Object.values(stock).some((value) =>
          value?.toString().toLowerCase().includes(searchKeyword.toLowerCase())
        )
      )
    : [];

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleModalOpen = (stock = null) => {
    if (stock) {
      setSelectedStock(stock);
      setFormData({
        name: stock.name || "",
        description: stock.description || "",
        skuDetails: stock.skuDetails || "",
        quantityInStock: stock.quantityInStock || "",
        reOrderLevel: stock.reOrderLevel || "",
      });
    } else {
      setSelectedStock(null);
      setFormData({
        name: "",
        description: "",
        skuDetails: "",
        quantityInStock: "",
        reOrderLevel: "",
      });
    }
    setShowModal(true);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate required fields
    if (!formData.name || !formData.description) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Name and Description are required",
      });
      return;
    }
  
    setLoading(true);
    try {
      let response;
      if (selectedStock) {
        response = await makeRequest2(
          `/api/stockandspare/${selectedFacilityId}/${selectedStock._id}`,
          "PUT",
          formData
        );
      } else {
        response = await makeRequest2(
          `/api/stockandspare/${selectedFacilityId}`,
          "POST",
          formData
        );
      }
  
      if (response.success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: selectedStock
            ? "Stock updated successfully"
            : "Stock added successfully",
        });
        fetchStocks();
        setShowModal(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error,
        });
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };  

  const handleDelete = async (stockId) => {
    confirmDialog({
      message: "Are you sure you want to delete this stock item?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        try {
          const response = await makeRequest2(
            `/api/stocks/${selectedFacilityId}/${stockId}`,
            "DELETE",
            null
          );

          if (response.success) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Stock deleted successfully",
            });
            fetchStocks();
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.error,
            });
          }
        } catch (err) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to delete stock",
          });
        }
      },
    });
  };

  const actionTemplate = (rowData) => {
    return (
      <div>
        <button
          className="btn btn-sm btn-primary me-2"
          onClick={() => handleModalOpen(rowData)}
        >
          <i className="ti ti-edit"></i>
        </button>
        <button
          className="btn btn-sm btn-danger"
          onClick={() => handleDelete(rowData._id)}
        >
          <i className="ti ti-trash"></i>
        </button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <input
          type="text"
          className="form-control w-25"
          placeholder="Search stocks..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
        <button className="btn btn-primary" onClick={() => handleModalOpen()}>
          <i className="ti ti-plus me-1"></i>Add New Stock
        </button>
      </div>
    );
  };

  const renderStockStatistics = () => {
    if (!stockStats.totalItems) {
      return (
        <div className="row">
          <div className="col-12">
            <Card className="p-4">
              <p className="text-center">No stock data available</p>
            </Card>
          </div>
        </div>
      );
    }

    const stockLevels = stocks.map((stock) => ({
      name: stock.skuDetails,
      current: stock.quantityInStock,
      reorder: stock.reOrderLevel,
    }));

    return (
      <div className="row">
        <div className="col-md-4 mb-4">
          <Card title="Total Items">
            <div className="text-center">
              <p className="text-3xl font-bold">{stockStats.totalItems}</p>
            </div>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card title="Low Stock Items">
            <div className="text-center">
              <p className="text-3xl font-bold text-red-600">
                {stockStats.lowStockItems}
              </p>
            </div>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card title="Average Stock Level">
            <div className="text-center">
              <p className="text-3xl font-bold text-blue-600">
                {stockStats.averageStock}
              </p>
            </div>
          </Card>
        </div>
        <div className="col-md-12">
          <Card title="Stock Levels">
            <div style={{ width: "100%", height: "400px" }}>
              <ResponsiveContainer>
                <BarChart data={stockLevels}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="current" fill="#8884d8" name="Current Stock" />
                  <Bar dataKey="reorder" fill="#82ca9d" name="Reorder Level" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body py-0">
                          <ul
                            className="nav nav-tabs profile-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="available-stocks-tab"
                                data-bs-toggle="tab"
                                href="#available-stocks"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-box me-2"></i>
                                Available Stocks
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="stock-statistics-tab"
                                data-bs-toggle="tab"
                                href="#stock-statistics"
                                role="tab"
                                aria-selected="false"
                              >
                                <i className="ti ti-chart-bar me-2"></i>
                                Stock Statistics
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="available-stocks"
                          role="tabpanel"
                          aria-labelledby="available-stocks-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={filteredStocks}
                                      emptyMessage="No stocks found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      loading={loading}
                                      header={renderHeader}
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                       <Column
                                        field="name"
                                        header="Name"
                                        sortable
                                      ></Column>
                                     <Column
                                        field="description"
                                        header="Description"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="skuDetails"
                                        header="SKU Details"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="quantityInStock"
                                        header="Qty in Stock"
                                        sortable
                                      ></Column>
                                      <Column
                                        field="reOrderLevel"
                                        header="Reorder Level"
                                        sortable
                                      ></Column>
                                      <Column
                                        body={actionTemplate}
                                        header="Actions"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane"
                          id="stock-statistics"
                          role="tabpanel"
                          aria-labelledby="stock-statistics-tab"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Stock Statistics</h5>
                                </div>
                                <div className="card-body">
                                  {renderStockStatistics()}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add/Edit Modal */}
      {showModal && (
  <div className="modal show d-block">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {selectedStock ? "Edit Stock" : "Add New Stock"}
          </h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setShowModal(false)}
          ></button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <textarea
                className="form-control"
                id="description"
                name="description"
                value={formData.description}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor="skuDetails" className="form-label">
                SKU Details
              </label>
              <input
                type="text"
                className="form-control"
                id="skuDetails"
                name="skuDetails"
                value={formData.skuDetails}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="quantityInStock" className="form-label">
                Quantity in Stock
              </label>
              <input
                type="number"
                className="form-control"
                id="quantityInStock"
                name="quantityInStock"
                value={formData.quantityInStock}
                onChange={handleInputChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="reOrderLevel" className="form-label">
                Reorder Level
              </label>
              <input
                type="number"
                className="form-control"
                id="reOrderLevel"
                name="reOrderLevel"
                value={formData.reOrderLevel}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
)}
    </Layout>
  );
};

export default StockAndSpareManagement;
