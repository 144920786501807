import React from "react";
import { Dialog } from "primereact/dialog";

const ReusableDialog = ({ header, visible, onHide, children, submitAction }) => {
    return (
        <Dialog
            header={header}
            visible={visible}
            style={{ width: '50vw' }}
            onHide={onHide}
        >
            <div className="row">
                <div className="col-md-12 mt-3">
                    {children} {/* Render dynamic content here */}

                </div>
            </div>
        </Dialog>
    );
};

export default ReusableDialog
