import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Dialog } from 'primereact/dialog';
import Layout from '../../component/layout';
import { toastify } from '../../../../utils/toast';
import { getItem } from '../../../../utils/localStorage';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { getContracts } from '../../../../utils/urls';
import EditContract from './edit_contract';
import DisableContract from './disable_contract';
import DeleteContract from './delete_contract';
import { Button } from 'primereact/button';
import { jsPDF } from 'jspdf'; 
import { getListOfFacilities } from "../../../../utils/urls";


const LevyContract = () => {
    const navigate = useNavigate();
    const [contracts, setContracts] = useState([]);
    const [filteredContracts, setFilteredContracts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedContractId, setSelectedContractId] = useState(null);
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);

    // Fetch contracts from the backend
    const fetchContracts = async () => {
        try {
            const facilityId = await getItem('selectedFacilityId');
            if (!facilityId) {
                toastify('No facility selected. Please select a facility first.', 'error');
                return;
            }

            const response = await makeRequest2(`${getContracts}/${facilityId}`, 'GET');
            if (response?.success && Array.isArray(response.data.contracts)) {
                setContracts(response.data.contracts);
                setFilteredContracts(response.data.contracts);
            } else {
                toastify(response?.data?.message || 'Failed to fetch contracts.', 'error');
                setContracts([]);
                setFilteredContracts([]);
            }
        } catch (error) {
            console.error('Error fetching contracts:', error);
            toastify(error.message || 'Error fetching contracts', 'error');
            setContracts([]);
            setFilteredContracts([]);
        }
    };

    useEffect(() => {
        fetchContracts();
    }, []);

    const closeEditModal = () => {
        setIsEditModalVisible(false);
        setSelectedContractId(null);
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        const filtered = contracts.filter((contract) =>
            contract.contractName.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredContracts(filtered);
    };

    const handleEditClick = (contractId) => {
        setSelectedContractId(contractId);
        setIsEditModalVisible(true);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="d-flex justify-content-start align-items-center">
    {/* Edit Button */}
    <Button
        icon="ti ti-edit"
        className="p-button-rounded p-button-info p-mr-2"
        onClick={() => handleEditClick(rowData._id)}
        tooltip="Edit"
    />

    {/* Delete Contract Button */}
    <DeleteContract 
        contractId={rowData._id} 
        status={rowData.status} 
        fetchContracts={fetchContracts} 
    />

    {/* Disable Contract Button */}
    <DisableContract 
        contractId={rowData._id} 
        currentStatus={rowData.disabled} 
        fetchContracts={fetchContracts} 
    />

    {/* Download Button */}
    <Button
        icon="ti ti-download"
        className="p-button-rounded p-button-success p-ml-2"
        onClick={() => downloadContract(rowData)} // Trigger the download function
        tooltip="Download"
    />
</div>

        );
    };

    const downloadContract = async (contract) => {
        // Retrieve the selected facility ID from localStorage
        const facilityId = await getItem("selectedFacilityId");
    
        if (!facilityId) {
            console.error("No facility selected.");
            return; // Exit if no facility is selected
        }
    
        // Fetch the facility details to get the name
        let facilityName = '';
        try {
            const response = await makeRequest2(getListOfFacilities, "GET", {}); // Make sure this endpoint is correct
            if (response.success) {
                const selectedFacility = response.data.find(facility => facility._id === facilityId);
                if (selectedFacility) {
                    facilityName = selectedFacility.name;
                } else {
                    console.error("Facility not found.");
                    return;
                }
            }
        } catch (error) {
            console.error("Error fetching facility details:", error.message);
            return;
        }
    
        // Create a new jsPDF instance
        const doc = new jsPDF();
    
        // Title Section: Facility Name
        doc.setFontSize(18);
        doc.setTextColor(0, 51, 102); // Dark blue color for title
        doc.text('Facility Name: ' + String(facilityName), 20, 20); // Use facility name here
    
        // Add some space between title and contract details
        doc.setFontSize(12);
        doc.text('Contract Details', 20, 30);
    
        // Add contract details in a structured format
        const content = [
            { label: 'Contract Name:', value: contract.contractName },
            { label: 'Customer:', value: contract.customerName },
            { label: 'Unit:', value: contract.unitName },
            { label: 'Amount:', value: contract.amount },
            { label: 'Start Date:', value: contract.startDate ? new Date(contract.startDate).toLocaleDateString() : 'N/A' },
            { label: 'End Date:', value: contract.endDate ? new Date(contract.endDate).toLocaleDateString() : 'N/A' },
            { label: 'Status:', value: contract.status }
        ];
    
        let yPosition = 40; // Initial Y position for contract details
    
        // Table Style
        const tableHeader = ['Field', 'Value'];
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(255, 255, 255);
        doc.setFillColor(0, 51, 102); // Dark blue header color
        doc.rect(20, yPosition - 5, 180, 10, 'F'); // Header background color
    
        tableHeader.forEach((header, index) => {
            doc.text(String(header), 20 + (index * 90), yPosition); // Ensure header is a string
        });
    
        yPosition += 10;
    
        // Contract details in table format
        doc.setFont('helvetica', 'normal');
        doc.setTextColor(0, 0, 0); // Reset text color to black
    
        content.forEach((item, index) => {
            if (item.label && item.value) {
                doc.text(String(item.label), 20, yPosition); // Convert to string
                doc.text(String(item.value), 110, yPosition); // Convert to string
                yPosition += 10;
            } else {
                console.error(`Missing data for: ${item.label}`);
            }
        });
    
        // Signature section
        const signatureYPosition = yPosition + 10;
        doc.text('User Signature: ____________________________', 20, signatureYPosition);
        doc.text('Property Manager Signature: ____________________________', 20, signatureYPosition + 10);
    
        // Save the document
        doc.save(`${contract.contractName}_contract.pdf`);
    };
    

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to="/app/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">Levy Management</li>
                                <li className="breadcrumb-item">Levy Contracts</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">
                            <h5>Levy Contracts</h5>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3 mb-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                </div>
                                <div className="col-md-9 mb-3 text-end">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => navigate('/facility/levy_management/contracts/add_contract')}
                                    >
                                        Add Contract
                                    </button>
                                </div>
                            </div>
                            <DataTable
                                value={filteredContracts}
                                emptyMessage="No contracts found."
                                paginator
                                rows={5}
                                stripedRows
                                tableStyle={{ minWidth: '50rem' }}
                            >
                                <Column field="contractName" header="Name" />
                                <Column
                                    field="startDate"
                                    header="Starting Date"
                                    body={(rowData) =>
                                        new Date(rowData.startDate).toISOString().split('T')[0]
                                    }
                                />
                                <Column
                                    field="endDate"
                                    header="Ending Date"
                                    body={(rowData) =>
                                        new Date(rowData.endDate).toISOString().split('T')[0]
                                    }
                                />
                                <Column field="status" header="Status" />
                                <Column body={actionBodyTemplate} header="Actions" />
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog
                visible={isEditModalVisible}
                onHide={closeEditModal}
                header="Edit Contract"
                style={{ width: '50vw' }}
                modal
                closable
            >
                {selectedContractId && (
                    <EditContract
                        contractId={selectedContractId}
                        onClose={closeEditModal}
                        fetchContracts={fetchContracts}  // Pass the fetchContracts function here
                    />
                )}
            </Dialog>
        </Layout>
    );
};

export default LevyContract;
