import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { getItem, clearStorage } from "../../../utils/localStorage";
function Nav() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [userName, setFullname] = useState("");
  const [userRole, setRole] = useState("");

  const HandleLogOut = async () => {
    await clearStorage();
    navigate("/");
  };

  const getCurrentUser = async () => {
    try {
      const currentUserValue = await getItem("APPUSER");

      let currentUser;
      // Check if the value needs to be parsed
      if (typeof currentUserValue === "string") {
        try {
          currentUser = JSON.parse(currentUserValue);
        } catch (err) {
          console.error("Error parsing APPUSER:", err);
        }
      } else {
        currentUser = currentUserValue; // Assume it's already an object
      }

      // Safely access and set user details
      if (currentUser?.user) {
        const fullName = currentUser.user.fullName;
        const role = currentUser.user.role;
        setFullname(fullName);
        setRole(role);
      } else {
        console.error("Invalid user structure:", currentUser);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);
  return (
    <nav className="pc-sidebar">
      <div className="navbar-wrapper">
        <div className="m-header">
          <Link to={"/app/"} className="b-brand text-primary">
            <img
              src="/assets/images/PayServeLogoFinal.png"
              className="img-fluid logo-lg"
              alt="logo"
              style={{ width: 180 }}
            />
            <span className="badge bg-light-success rounded-pill ms-2 theme-version">
              v1.0
            </span>
          </Link>
        </div>
        <div className="navbar-content" style={{ overflowY: "scroll" }}>
          <div className="card pc-user-card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img
                    src="/assets/images/user/avatar-1.jpg"
                    alt="user-image"
                    className="user-avtar wid-45 rounded-circle"
                  />
                </div>
                <div className="flex-grow-1 ms-3 me-2">
                  <h6 className="mb-0">{userName}</h6>
                </div>
                <Link
                  className="btn btn-icon btn-link-secondary avtar"
                  data-bs-toggle="collapse"
                  to="#pc_sidebar_userlink"
                >
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-sort-outline" />
                  </svg>
                </Link>
              </div>
              <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                <div className="pt-3">
                  {/* <Link to={"/app/settings"}>
                                        <i className="ti ti-settings" />
                                        <span>Settings</span>
                                    </Link> */}

                  <Link
                    to={"#!"}
                    onClick={() => {
                      HandleLogOut();
                    }}
                  >
                    <i className="ti ti-power" />
                    <span>Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ul className="pc-navbar mb-5">
            {/* <li className={`pc-item ${currentPath === '/app/' ? 'active' : ''}`}>
                            <Link to={'/app/'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-home" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Dashboard</span>


                            </Link>
                        </li> */}

            <li className="pc-item pc-caption">
              <label>Facility Management</label>
              <svg className="pc-icon">
                <use xlinkHref="#custom-presentation-chart" />
              </svg>
            </li>

            <li
              className={`pc-item ${
                currentPath === "/app/facilities" ? "active" : ""
              }`}
            >
              <Link to={"/app/facilities"} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-element-plus" />
                  </svg>
                </span>

                <span className="pc-mtext">Facilities</span>
              </Link>
            </li>

            {/* <li className="pc-item pc-caption">
                            <label>More</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/app/customer_management' || currentPath === '/app/add_new_customer' || currentPath === '/app/import_customers'  ? 'active' : ''}`}>
                            <Link to={'/app/customer_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-profile-2user-outline" />
                                    </svg>
                                </span>
                                
                                <span className="pc-mtext">Customer Management</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/app/campaign_management'  ? 'active' : ''}`}>
                            <Link to={'/app/campaign_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-data" />
                                    </svg>
                                </span>
                                
                                <span className="pc-mtext">Campaign Management</span>
                            </Link>
                        </li>
                      
                        <li className={`pc-item ${currentPath === '/app/ticket_management'  ? 'active' : ''}`}>
                            <Link to={'/app/ticket_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-text-block" />
                                    </svg>
                                </span>
                                
                                <span className="pc-mtext">Ticket Management</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '#'  ? 'active' : ''}`}>
                            <Link to={'#'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-share-bold" />
                                    </svg>
                                </span>
                                
                                <span className="pc-mtext">Survey Management</span>
                            </Link>
                        </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Nav;
