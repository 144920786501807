import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Layout from "../component/layout";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { getListOfFacilities } from "../../../utils/urls";
import { setItem, getItem } from "../../../utils/localStorage";
import { useFacilityStore } from "../../../app/z-store/store";

function Facilities() {
  const navigate = useNavigate();
  const [facilities, setFacilities] = useState([]);
  const setFacilityId = useFacilityStore((state) => state.setFacilityId);
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);

  const getFacilities = async () => {
    try {
      const response = await makeRequest2(getListOfFacilities, "GET", {});

      if (response.success) {
        const currentUserValue = await getItem("APPUSER");

        let currentUser;
        // Check if currentUserValue is a string or an object
        if (typeof currentUserValue === "string") {
          try {
            currentUser = JSON.parse(currentUserValue);
          } catch (err) {
            console.error("Error parsing APPUSER:", err);
            return toastify("Invalid user data.", "error");
          }
        } else {
          currentUser = currentUserValue; // Assume it's already parsed
        }

        const role = currentUser?.user?.role;
        if (role !== "guard") {
          setFacilities(response.data);
        } else {
          const facilityId = currentUser?.user?.facilityId;
          const filter = response.data.filter((x) => x._id === facilityId);
          setFacilities(filter);
        }
      }
    } catch (err) {
      console.error("Error in getFacilities:", err.message);
      toastify("Server error.", "error");
    }
  };

  const handleFacilityClick = async (facility) => {
    try {
      await setItem("selectedFacilityId", facility._id);
      setFacilityId(facility._id);

      const data = await getItem("selectedFacilityId");
      console.log("Selected Facility ID:", data);

      // Retrieve the APPUSER data
      const currentUserValue = await getItem("APPUSER");
      console.log("Raw APPUSER:", currentUserValue);

      let currentUser;
      // Check the type of currentUserValue before parsing
      if (typeof currentUserValue === "string") {
        try {
          currentUser = JSON.parse(currentUserValue);
        } catch (err) {
          console.error("Error parsing APPUSER:", err);
          return toastify("Invalid user data.", "error");
        }
      } else {
        currentUser = currentUserValue; // Assume it's already an object
      }

      // Handle navigation based on role
      const role = currentUser?.user?.role;
      if (role !== "guard") {
        navigate("/facility/", { state: { facility } });
      } else if (role === "guard") {
        navigate("/facility/visitor_access_management/home");
      }
    } catch (err) {
      console.error("Error in handleFacilityClick:", err.message);
      toastify("An error occurred.", "error");
    }
  };

  useEffect(() => {
    getFacilities();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"#"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="#">Facilities</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div className="row">
            {facilities.length === 0 ? (
              <div className="col-md-12 text-center">
                <p>No facilities available</p>
              </div>
            ) : (
              facilities.map((facility, index) => (
                <div className="col-md-3" key={index}>
                  <div className="pc-component">
                    <div
                      className="card mb-2 text-center"
                      style={{ position: "relative" }}
                    >
                      <span
                        className="badge bg-warning"
                        style={{ position: "absolute", top: 5, left: 5 }}
                      >
                        {index + 1}
                      </span>
                      <span className="badge bg-light-primary">
                        {" "}
                        <i className="ti ti-home f-50 mt-3"></i>
                      </span>

                      <div
                        className="card-body"
                        onClick={() => handleFacilityClick(facility)}
                        style={{ cursor: "pointer" }}
                      >
                        <h5 className="card-title">{facility.name}</h5>
                        <p className="card-text">
                          <span className="badge bg-light-primary">
                            {facility.location}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Facilities;
