import React from "react";
import { Link } from "react-router-dom";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../../component/layout";
function AccessControl() {
  const header = (
    <div className="flex align-items-center justify-content-end gap-2">
      <Button
        type="button"
        label="CSV"
        rounded
        onClick={() => {}}
        data-pr-tooltip="CSV"
      />
      <Button
        type="button"
        label="XLS"
        severity="success"
        rounded
        onClick={() => {}}
        data-pr-tooltip="XLS"
      />
      <Button
        type="button"
        label="PDF"
        severity="warning"
        rounded
        onClick={() => {}}
        data-pr-tooltip="PDF"
      />
    </div>
  );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={"/facility/visitor_access_management/access_control"}
                  >
                    Access Control
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body py-0">
                          <ul
                            className="nav nav-tabs profile-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="profile-tab-1"
                                data-bs-toggle="tab"
                                href="#profile-1"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-rotate-2 me-2"></i>Access
                                Management
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-1"
                                data-bs-toggle="tab"
                                href="#profile-3"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-bell me-2"></i>Notifications
                                & Alerts
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-2"
                                data-bs-toggle="tab"
                                href="#profile-4"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-report-money me-2"></i>
                                Reports
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="profile-1"
                          role="tabpanel"
                          aria-labelledby="profile-tab-1"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="col-md-3 col-xs-12  mb-3">
                                    <br />
                                    <input
                                      className="form-control"
                                      placeholder="Search here"
                                      value={""}
                                      onChange={(e) => {}}
                                    />
                                  </div>
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={[]}
                                      header={header}
                                      emptyMessage="No customer accounts found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="customer"
                                        header="Customer"
                                      ></Column>
                                      <Column
                                        field="unit"
                                        header="Unit"
                                      ></Column>
                                      <Column
                                        field="meter_sn"
                                        header="Meter S/N"
                                      ></Column>
                                      <Column
                                        field="billing_method"
                                        header="Billing Method"
                                      ></Column>
                                      <Column
                                        field="balance"
                                        header="Balance"
                                      ></Column>
                                      <Column
                                        field="status"
                                        header="Status"
                                      ></Column>
                                      <Column
                                        field="last_updated"
                                        header="Last Updated"
                                      ></Column>
                                      <Column
                                        field="last_updated"
                                        header="Actions"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-2"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="col-md-3 col-xs-12  mb-3">
                                    <br />
                                    <input
                                      className="form-control"
                                      placeholder="Search here"
                                      value={""}
                                      onChange={(e) => {}}
                                    />
                                  </div>
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={[]}
                                      header={header}
                                      emptyMessage="No customer accounts found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="customer"
                                        header="Customer"
                                      ></Column>
                                      <Column
                                        field="unit"
                                        header="Unit"
                                      ></Column>
                                      <Column
                                        field="meter_sn"
                                        header="Meter S/N"
                                      ></Column>
                                      <Column
                                        field="billing_method"
                                        header="Billing Method"
                                      ></Column>
                                      <Column
                                        field="balance"
                                        header="Balance"
                                      ></Column>

                                      <Column
                                        field="status"
                                        header="Status"
                                      ></Column>
                                      <Column
                                        field="last_updated"
                                        header="Last Updated"
                                      ></Column>
                                      <Column
                                        field="last_updated"
                                        header="Actions"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-3"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-md-3 col-xs-12 mt-3">
                                      <br />
                                      <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={""}
                                        onChange={(e) => {}}
                                      />
                                    </div>

                                    <div className="col-md-8 col-xs-12 mt-2">
                                      <div className="row">
                                        <div className="col-md-4 col-xs-5 mt-2">
                                          <label>Start Date</label>
                                          <input
                                            className="form-control"
                                            type="date"
                                          />
                                        </div>
                                        <div className="col-md-4 col-xs-5 mt-2">
                                          <label>End Date</label>
                                          <input
                                            className="form-control"
                                            type="date"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body position-relative">
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={[]}
                                      header={header}
                                      emptyMessage="No invoices found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="ref"
                                        sortable
                                        header="Invoice Number"
                                      ></Column>
                                      <Column
                                        field="ref"
                                        header="Customer"
                                      ></Column>
                                      <Column
                                        field="amount"
                                        header="Amount"
                                      ></Column>
                                      <Column
                                        field="date_issued"
                                        sortable
                                        header="Issued Date"
                                      ></Column>
                                      <Column
                                        field="amount_paid"
                                        header="Deadline Date"
                                      ></Column>
                                      <Column
                                        field="status"
                                        sortable
                                        header="Status"
                                      ></Column>
                                      <Column
                                        field=""
                                        header="Actions"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-4"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                        >
                          <div className="row">
                            <div className="col-lg-12 col-xxl-12">
                              <div className="card">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-md-3 col-xs-12 mt-3">
                                      <br />
                                      <input
                                        className="form-control"
                                        placeholder="Search here"
                                        value={""}
                                        onChange={(e) => {}}
                                      />
                                    </div>
                                    <div className="col-md-1 col-xs-12  mb-3">
                                      <br />
                                      <br />
                                      <div
                                        className="btn-group-dropdown"
                                        style={{ float: "right" }}
                                      >
                                        <button
                                          className="btn btn-outline-default dropdown-toggle"
                                          type="button"
                                          data-bs-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="ti ti-filter"></i>
                                        </button>
                                        <div className="dropdown-menu">
                                          <Link
                                            className="dropdown-item"
                                            to="#!"
                                            onClick={() => {}}
                                          >
                                            All
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to="#!"
                                            onClick={() => {}}
                                          >
                                            Power
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to="#!"
                                            onClick={() => {}}
                                          >
                                            Water
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-8 col-xs-12 mt-2">
                                      <div className="row">
                                        <div className="col-md-4 col-xs-5 mt-2">
                                          <label>Start Date</label>
                                          <input
                                            className="form-control"
                                            type="date"
                                          />
                                        </div>
                                        <div className="col-md-4 col-xs-5 mt-2">
                                          <label>End Date</label>
                                          <input
                                            className="form-control"
                                            type="date"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-body position-relative">
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={[]}
                                      header={header}
                                      emptyMessage="No transactions found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      tableStyle={{ minWidth: "50rem" }}
                                    >
                                      <Column
                                        field="ref"
                                        sortable
                                        header="Ref Number"
                                      ></Column>
                                      <Column
                                        field="payment_mode"
                                        sortable
                                        header="Payment Mode"
                                      ></Column>
                                      <Column
                                        field="amount"
                                        header="Utility"
                                      ></Column>
                                      <Column
                                        field="amount_paid"
                                        header="Amount Paid"
                                      ></Column>
                                      <Column
                                        field="date"
                                        sortable
                                        header="Date"
                                      ></Column>
                                      <Column
                                        field=""
                                        header="Actions"
                                      ></Column>
                                    </DataTable>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AccessControl;
