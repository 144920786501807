import AssetManagement from "./components/AssetManagement";
import StockAndSpareManagement from "./components/StockAndSpareParts";
import ServiceVendor from "./components/service_vendor";

const maintenanceRouter = [
  {
    path: "/facilitiy/maintenance/asset",
    element: <AssetManagement />,
  },
  {
    path: "/facilitiy/maintenance/stock_and_spare_parts",
    element: <StockAndSpareManagement />,
  },
  {
    path: "/facilitiy/maintenance/sla",
    element: <ServiceVendor />,
  },
  {
    path: "/facilitiy/maintenance/asset",
    element: <AssetManagement />,
  },
];

export default maintenanceRouter;
