import React from 'react';
import { Dropdown } from 'primereact/dropdown';
import { useReminderForm } from '../hooks/useReminderForm';

const ReminderForm = ({ levies, module, onSubmitSuccess }) => {
  const {
    type,
    selectedLevyId,
    reminderData,
    handleReminderSubmit,
    handleLevyChange,
    handleTypeChange,
    handleInputChange
  } = useReminderForm(levies, module, onSubmitSuccess);

  return (
    <form onSubmit={handleReminderSubmit}>
         <input type="hidden" name="module" value={module} />
      <div className="form-group mb-4">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={reminderData.name}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group mb-4">
        <label htmlFor="levy">Select Levy</label>
        <Dropdown
          value={selectedLevyId}
          options={levies.map(levy => ({ label: levy.levyName, value: levy._id }))}
          onChange={(e) => {
            const selectedLevy = levies.find(levy => levy._id === e.value);
            handleLevyChange(selectedLevy);
          }}
          placeholder="Select a levy"
          className="w-100"
          required
        />
      </div>

      <div className="form-group mb-4">
        <label htmlFor="type">Type</label>
        <Dropdown
          value={type}
          options={[
            { label: "One Time", value: "onetime" },
            { label: "Recurring", value: "recurring" }
          ]}
          onChange={(e) => handleTypeChange(e.value)}
          placeholder="Select Reminder Type"
          className="w-100"
          required
        />
      </div>

      {type === 'onetime' && (
        <div className="form-group mb-4">
          <label htmlFor="scheduledDate">Scheduled Date</label>
          <input
            type="date"
            className="form-control"
            id="scheduledDate"
            name="scheduledDate"
            value={reminderData.scheduledDate}
            onChange={handleInputChange}
            required
          />
        </div>
      )}

      {type === 'recurring' && (
        <>
          <div className="form-group mb-4">
            <label htmlFor="frequency">Frequency</label>
            <Dropdown
              value={reminderData.frequency}
              options={[
                { label: "Daily", value: "daily" },
                { label: "Weekly", value: "weekly" },
                { label: "Bi-weekly", value: "bi-weekly" },
                { label: "Monthly", value: "monthly" }
              ]}
              onChange={(e) => handleInputChange({ 
                target: { 
                  name: 'frequency', 
                  value: e.value 
                } 
              })}
              placeholder="Select Frequency"
              className="w-100"
              required
            />
          </div>

          <div className="form-group mb-4">
            <label htmlFor="day">Day</label>
            <Dropdown
              value={reminderData.day}
              options={[
                'Monday', 'Tuesday', 'Wednesday', 'Thursday', 
                'Friday', 'Saturday', 'Sunday'
              ].map(day => ({ label: day, value: day }))}
              onChange={(e) => handleInputChange({ 
                target: { 
                  name: 'day', 
                  value: e.value 
                } 
              })}
              placeholder="Select Day"
              className="w-100"
              required
            />
          </div>
        </>
      )}

      <div className="form-group mb-4">
        <label htmlFor="time">Time</label>
        <input
          type="time"
          className="form-control"
          id="time"
          name="time"
          value={reminderData.time}
          onChange={handleInputChange}
          required
        />
      </div>

      <div className="form-group mb-4">
        <label>Notification Types</label>
        <div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="emailNotification"
              name="notificationTypes"
              value="EMAIL"
            />
            <label className="form-check-label" htmlFor="emailNotification">
              Email
            </label>
          </div>
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="smsNotification"
              name="notificationTypes"
              value="SMS"
            />
            <label className="form-check-label" htmlFor="smsNotification">
              SMS
            </label>
          </div>
        </div>
      </div>

      <button type="submit" className="btn btn-primary">
        Submit
      </button>
    </form>
  );
};

export default ReminderForm;