import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import Layout from '../../component/layout';
import ReusableDialog from '../../component/reusableDialog';
import PenaltyForm from './components/penaltyForm';
import ReminderForm from './components/reminderForm';
import { useSettingsData } from './hooks/useSettings';

function Settings() {
    const {
        reminders,
        penalties,
        levies,
        refetchReminders,
        refetchPenalties
    } = useSettingsData();

    const [activeTab, setActiveTab] = useState('penalty');
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [dialogType, setDialogType] = useState(null);
    const [moduleType] = useState('levy_management'); // Removed setter, set to default value

    const handleShowDialog = (type) => {
        setDialogType(type);
        setVisibleDialog(true);
    };

    const handleHideDialog = () => {
        setVisibleDialog(false);
        setDialogType(null);
    };

    const renderDialogContent = () => {
        switch (dialogType) {
            case 'penalty':
                return (
                    <PenaltyForm
                        levies={levies}
                        module={moduleType}
                        onSubmitSuccess={() => {
                            refetchPenalties();
                            handleHideDialog();
                        }}
                    />
                );
            case 'reminder':
                return (
                    <ReminderForm
                        levies={levies}
                        module={moduleType}
                        onSubmitSuccess={() => {
                            refetchReminders();
                            handleHideDialog();
                        }}
                    />
                );
            default:
                return null;
        }
    };

    const renderPenaltiesTable = () => (
        <div className="card">
            <div className='card-body'>
                <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                        <div className="card-body position-relative">
                            <div className="row">
                                <div className="col-md-3 col-xs-12 mb-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search here"
                                    />
                                </div>
                                <div className="col-md-9 col-xs-12 mb-3">
                                    <div style={{ float: 'right' }}>
                                        <button
                                            className='btn btn-primary'
                                            onClick={() => handleShowDialog('penalty')}
                                        >
                                            Add New Penalty
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dt-responsive table-responsive">
                                        <DataTable
                                            value={moduleType === 'levy_management' ? penalties : []}
                                            emptyMessage="No Penalties found."
                                            sortMode="multiple"
                                            paginator
                                            rows={5}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="name" header="Name" />
                                            <Column field="type" header="Type" />
                                            <Column field="effectDays" header="Effect Days" />
                                            <Column
                                                field="percentage"
                                                header="Percentage"
                                                body={(rowData) => rowData.percentage ? `${rowData.percentage}%` : 'N/A'}
                                            />
                                            <Column field="amount" header="Amount" sortable />
                                            <Column
                                                field="isActive"
                                                header="Is Active"
                                                body={(rowData) => rowData.isActive ? 'Yes' : 'No'}
                                            />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    const renderRemindersTable = () => (
        <div className="card">
            <div className='card-body'>
                <div className="row">
                    <div className="col-lg-12 col-xxl-12">
                        <div className="card-body position-relative">
                            <div className="row">
                                <div className="col-md-3 col-xs-12 mb-3">
                                    <input
                                        className="form-control"
                                        placeholder="Search here"
                                    />
                                </div>
                                <div className="col-md-9 col-xs-12 mb-3">
                                    <div style={{ float: 'right' }}>
                                        <button
                                            className='btn btn-primary'
                                            onClick={() => handleShowDialog('reminder')}
                                        >
                                            Add New Reminder
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="dt-responsive table-responsive">
                                        <DataTable
                                            value={moduleType === 'levy_management' ? reminders : []}
                                            emptyMessage="No Reminders found."
                                            sortMode="multiple"
                                            paginator
                                            rows={5}
                                            stripedRows
                                            tableStyle={{ minWidth: '50rem' }}
                                        >
                                            <Column field="name" header="Name" />
                                            <Column field="type" header="Type" />
                                            <Column field="time" header="Time" />
                                            <Column field="day" header="Day" />
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <Layout>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <Link to={"/facility/"}>Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={"/resident/settings"}>Settings</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <div className="card">
                        <div className="card-header">

                            <div className="card">
                                <div className="card-body py-0">
                                    <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${activeTab === 'penalty' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('penalty')}
                                                href="#profile-1"
                                                role="tab"
                                            >
                                                <i className="fas fa-flag-checkered me-2"></i>Penalties
                                            </a>
                                        </li>
                                        <li className="nav-item">
                                            <a
                                                className={`nav-link ${activeTab === 'reminder' ? 'active' : ''}`}
                                                onClick={() => setActiveTab('reminder')}
                                                href="#profile-2"
                                                role="tab"
                                            >
                                                <i className="fas fa-backward me-2"></i>Reminders
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="tab-content">
                                <div
                                    className={`tab-pane ${activeTab === 'penalty' ? 'show active' : ''}`}
                                    id="profile-1"
                                    role="tabpanel"
                                >
                                    {renderPenaltiesTable()}
                                </div>
                                <div
                                    className={`tab-pane ${activeTab === 'reminder' ? 'show active' : ''}`}
                                    id="profile-2"
                                    role="tabpanel"
                                >
                                    {renderRemindersTable()}
                                </div>
                            </div>

                            <ReusableDialog
                                header={
                                    dialogType === 'penalty' ? "Add New Penalty"
                                        : dialogType === 'reminder' ? "Add New Reminder"
                                            : ""
                                }
                                visible={visibleDialog}
                                onHide={handleHideDialog}
                            >
                                {renderDialogContent()}
                            </ReusableDialog>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Settings;