import { useState, useEffect } from 'react';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import {
  addReminder,
  addPenalty,
  facilityReminders,
  facilityPenalties,
  getLeviesURL
} from '../../../../../utils/urls';
import { getItem } from '../../../../../utils/localStorage';
import { toast } from 'react-toastify';

export const useSettingsData = () => {
  const [reminders, setReminders] = useState([]);
  const [penalties, setPenalties] = useState([]);
  const [levies, setLevies] = useState([]);

  const fetchFacilityReminders = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      const url = facilityReminders + '/' + facilityId;
      const response = await makeRequest2(url, 'GET', {});

      if (response.success) {
        setReminders(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchFacilityPenalties = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      const url = facilityPenalties + '/' + facilityId;
      const response = await makeRequest2(url, 'GET', {});

      if (response.success) {
        setPenalties(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const fetchLevies = async () => {
    try {
      const facilityId = await getItem('selectedFacilityId');
      const url = getLeviesURL + '/' + facilityId;
      const response = await makeRequest2(url, 'GET', {});

      if (response.success) {
        setLevies(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    fetchFacilityReminders();
    fetchFacilityPenalties();
    fetchLevies();
  }, []);

  return {
    reminders,
    penalties,
    levies,
    refetchReminders: fetchFacilityReminders,
    refetchPenalties: fetchFacilityPenalties
  };
};