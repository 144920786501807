import { useState } from 'react';
import { toast } from 'react-toastify';
import { getItem } from '../../../../../utils/localStorage';
import { makeRequest2 } from '../../../../../utils/makeRequest';
import { addPenalty } from '../../../../../utils/urls';

export const usePenaltyForm = (levies, module, onSubmitSuccess) => {
    const [type, setType] = useState('');
    const [selectedLevyId, setSelectedLevyId] = useState('');
    const [penaltyData, setPenaltyData] = useState({
        name: '',
        type: '',
        amount: '',
        percentage: '',
        effectDays: '',
        isActive: false
    });

    const handlePenaltySubmit = async (e) => {
        e.preventDefault();

        try {
            const facilityId = await getItem('selectedFacilityId');
            const url = addPenalty + '/' + facilityId;

            const submissionData = {
                name: penaltyData.name,
                type: type,
                effectDays: penaltyData.effectDays,
                amount: type === 'fixed' ? penaltyData.amount : null,
                percentage: type === 'percentage' ? penaltyData.percentage : null,
                isActive: penaltyData.isActive,
                levyId: selectedLevyId,
                module: module || '',
                moduleType: module
            };

            // Validation
            const requiredFields = ['name', 'type', 'effectDays', 'isActive', 'levyId', 'module'];
            const missingFields = requiredFields.filter(field => !submissionData[field]);

            if (missingFields.length > 0) {
                toast.error(`Please fill in all required fields: ${missingFields.join(', ')}`);
                return;
            }

            // Type-specific validation
            if (type === 'fixed' && !submissionData.amount) {
                toast.error('Please enter an amount for fixed type penalty');
                return;
            }

            if (type === 'percentage' && !submissionData.percentage) {
                toast.error('Please enter a percentage for percentage type penalty');
                return;
            }

            const response = await makeRequest2(url, 'POST', submissionData);

            if (response.success) {
                toast.success(response.data);
                onSubmitSuccess();

                // Reset form state
                setPenaltyData({
                    name: '',
                    type: '',
                    amount: '',
                    percentage: '',
                    effectDays: '',
                    isActive: false
                });
                setType('');
                setSelectedLevyId('');
            } else {
                throw new Error(response.error);
            }
        } catch (err) {
            toast.error(err.message);
        }
    };

    const handleLevyChange = (levy) => {
        setSelectedLevyId(levy._id);
    };

    const handleTypeChange = (e) => {
        const selectedType = e.value;
        setType(selectedType);
        setPenaltyData(prev => ({
            ...prev,
            type: selectedType,
            // Reset other type-specific fields
            amount: selectedType === 'fixed' ? prev.amount : '',
            percentage: selectedType === 'percentage' ? prev.percentage : ''
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setPenaltyData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return {
        type,
        selectedLevyId,
        penaltyData,
        handlePenaltySubmit,
        handleLevyChange,
        handleTypeChange,
        handleInputChange
    };
};