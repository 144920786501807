import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { makeRequest2 } from "../../../../../utils/makeRequest";
import { useFacilityStore } from "../../../../../app/z-store/store"; 

import "./invoice.css";

const InvoiceTemplate = ({ invoice, companyDetails }) => {
  if (!invoice) return null;

  return (
    <div className="max-w-4xl mx-auto print:shadow-none">
      {/* Invoice header */}
      <div className="h-40 bg-sky-500 p-8 print:bg-sky-500">
        <div className="flex justify-between">
          <div className="text-4xl font-semibold text-slate-200 self-center uppercase print:text-slate-200">
            Invoice
          </div>
          <div className="flex flex-row gap-8 text-sky-50 print:text-sky-50">
            <ul className="text-right">
              <li>INVOICE #</li>
              <li>INVOICE DATE</li>
              <li>DUE DATE</li>
              <li>STATUS</li>
            </ul>
            <ul>
              <li>{invoice.invoiceNumber}</li>
              <li>{new Date(invoice.issueDate).toLocaleDateString()}</li>
              <li>{new Date(invoice.dueDate).toLocaleDateString()}</li>
              <li>{invoice.status}</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Invoice Details */}
      <div className="flex flex-row p-8">
        <div className="w-[60%] flex flex-row justify-between">
          <div>
            <h6 className="text-slate-500 uppercase font-semibold">from</h6>
            <h6 className="text-slate-900 capitalize font-mono font-semibold">
              {companyDetails?.name || "Company Name"}
            </h6>
            <h6 className="text-slate-900 capitalize font-mono font-semibold">
              {companyDetails?.address || "Company Address"}
            </h6>
          </div>
          <div>
            <h6 className="text-slate-500 uppercase font-semibold">bill to</h6>
            <h6 className="text-slate-900 capitalize font-mono font-semibold">
              {invoice.client?.name}
            </h6>
            <h6 className="text-slate-900 capitalize font-mono font-semibold">
              Unit: {invoice.unit?.name}
            </h6>
          </div>
        </div>
        <div className="w-1/3">
          <div className="flex justify-end">
            <div className="flex flex-col space-y-8">
              <div className="text-slate-500 uppercase text-xl text-end">
                Invoice Total
              </div>
              <div className="text-sky-600 text-2xl text-end font-bold">
                Ksh {invoice.totalAmount?.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-1 bg-sky-500 mx-8 mt-8"></div>

      {/* Invoice Items */}
      <div className="px-8 pt-2">
        <div className="flex flex-row">
          <div className="w-2/3">
            <h6 className="text-sky-600 text-lg font-semibold uppercase">
              Description
            </h6>
            {invoice.items?.map((item, index) => (
              <h6 key={index} className="text-slate-900 font-semibold">
                {item.description}
              </h6>
            ))}
          </div>
          <div className="w-28">
            <h6 className="text-sky-600 text-lg font-semibold uppercase whitespace-nowrap">
              unit price
            </h6>
            {invoice.items?.map((item, index) => (
              <h6 key={index} className="text-slate-900 font-semibold text-end">
                {item.unitPrice.toFixed(2)}
              </h6>
            ))}
          </div>
          <div className="w-16 text-center">
            <h6 className="text-sky-600 text-lg font-semibold uppercase">
              qty
            </h6>
            {invoice.items?.map((item, index) => (
              <h6 key={index} className="text-slate-900 font-semibold text-end">
                {item.quantity}
              </h6>
            ))}
          </div>
          <div className="w-24">
            <h6 className="text-sky-600 text-lg font-semibold uppercase">
              amount
            </h6>
            {invoice.items?.map((item, index) => (
              <h6 key={index} className="text-slate-900 font-semibold text-end">
                {(item.quantity * item.unitPrice).toFixed(2)}
              </h6>
            ))}
          </div>
        </div>

        {/* Totals */}
        <div className="flex justify-end mt-6 px-5">
          <div className="flex">
            <div className="flex flex-col me-8">
              <h6 className="text-sky-500 text-lg font-semibold capitalize">
                Subtotal
              </h6>
              <h6 className="text-sky-500 text-lg font-semibold capitalize">
                Tax ({invoice.taxRate || 0}%)
              </h6>
              {invoice.penalty > 0 && (
                <h6 className="text-sky-500 text-lg font-semibold capitalize">
                  Penalty
                </h6>
              )}
            </div>
            <div className="flex flex-col">
              <h6 className="text-slate-900 text-lg font-semibold capitalize">
                Ksh {invoice.subTotal?.toFixed(2)}
              </h6>
              <h6 className="text-slate-900 text-lg font-semibold capitalize">
                Ksh {invoice.tax?.toFixed(2)}
              </h6>
              {invoice.penalty > 0 && (
                <h6 className="text-slate-900 text-lg font-semibold capitalize">
                  Ksh {invoice.penalty?.toFixed(2)}
                </h6>
              )}
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6 px-5">
          <h6 className="text-sky-500 text-lg font-semibold pe-5 uppercase">
            Total
          </h6>
          <h6 className="text-slate-900 text-lg font-semibold capitalize">
            Ksh {invoice.totalAmount?.toFixed(2)}
          </h6>
        </div>
      </div>

      {/* Invoice Footer */}
      <div className="flex flex-col p-8 border-t border-gray-200 bg-slate-200 mt-52">
        <h6 className="text-slate-500 text-sm uppercase font-semibold">
          terms and conditions
        </h6>
        <h6 className="text-slate-900 font-semibold">
          {invoice.invoiceNote || "Payment is due within 15 days"}
        </h6>
        <div className="pt-4">
          <h6 className="text-slate-900 font-semibold">Payment Details:</h6>
          <h6 className="text-slate-900 font-semibold">
            Bank: {companyDetails?.bankName || "Bank Name"}
          </h6>
          <h6 className="text-slate-900 font-semibold">
            Account: {companyDetails?.accountNumber || "Account Number"}
          </h6>
        </div>
      </div>
    </div>
  );
};

const InvoiceManagement = ({ facilityId }) => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [dateFilters, setDateFilters] = useState({
    startDate: null,
    endDate: null,
  });
  const [companyDetails, setCompanyDetails] = useState(null);
  const toast = useRef(null);

  const selectedFacilityId = useFacilityStore((state) => state.facilityId);

  useEffect(() => {
    if (selectedFacilityId) {
      fetchInvoices(selectedFacilityId); // Use facilityId to fetch invoices
      console.log("Facility ID:", selectedFacilityId);
    }
  }, [selectedFacilityId]); 

  const fetchCompanyDetails = async () => {
    try {
      const response = await makeRequest2(
        `/api/facilities/${selectedFacilityId}/details`,
        "GET"
      );
      if (response.success) {
        setCompanyDetails(response.data);
      }
    } catch (error) {
      console.error("Failed to fetch company details:", error);
    }
  };

  const fetchInvoices = async () => {
    try {
      setLoading(true);
      // const queryParams = new URLSearchParams();
      // if (dateFilters.startDate) {
      //   queryParams.append("startDate", dateFilters.startDate.toISOString());
      // }
      // if (dateFilters.endDate) {
      //   queryParams.append("endDate", dateFilters.endDate.toISOString());
      // }
console.log(facilityId + ' john')
      const response = await makeRequest2(
        `/api/app/levy_management/get_facility_invoices/${selectedFacilityId}`,
        "GET"
      );
      console.log(response)

      if (response.success) {
        setInvoices(response.data);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error || "Failed to fetch invoices",
        });
      }
    } catch (error) {
      console.log('hello')
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (invoiceId) => {
    confirmDialog({
      message: "Are you sure you want to delete this invoice?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        try {
          const response = await makeRequest2(
            `/api/levy-management/delete_invoice/${invoiceId}`,
            "DELETE"
          );

          if (response.success) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Invoice deleted successfully",
            });
            fetchInvoices();
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.error || "Failed to delete invoice",
            });
          }
        } catch (error) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: error.message,
          });
        }
      },
    });
  };

  const handlePrint = () => {
    window.print();
  };

  const handleSendInvoice = async () => {
    try {
      const response = await makeRequest2(
        `/api/levy-management/send_invoice/${selectedInvoice._id}`,
        "POST"
      );

      if (response.success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: "Invoice sent successfully",
        });
        setShowInvoiceModal(false);
      } else {
        throw new Error(response.error || "Failed to send invoice");
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: error.message,
      });
    }
  };

  const handleRowClick = (e) => {
    setSelectedInvoice(e.data);
    setShowInvoiceModal(true);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-Ke", {
      style: "currency",
      currency: "Kes",
    }).format(value);
  };

  const formatDate = (value) => {
    return new Date(value).toLocaleDateString();
  };

  const statusTemplate = (rowData) => {
    const getStatusClass = (status) => {
      switch (status) {
        case "Paid":
          return "bg-green-100 text-green-700";
        case "Pending":
          return "bg-yellow-100 text-yellow-700";
        case "Overdue":
          return "bg-red-100 text-red-700";
        default:
          return "bg-gray-100 text-gray-700";
      }
    };

    return (
      <span
        className={`px-3 py-1 rounded-full text-sm ${getStatusClass(
          rowData.status
        )}`}
      >
        {rowData.status}
      </span>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex gap-2">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-text"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedInvoice(rowData);
            setShowInvoiceModal(true);
          }}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(rowData._id);
          }}
        />
      </div>
    );
  };

  const invoiceModalFooter = (
    <div className="flex justify-end gap-2">
      <Button
        label="Send"
        icon="pi pi-send"
        onClick={handleSendInvoice}
        className="p-button-success"
      />
      <Button
        label="Print"
        icon="pi pi-print"
        onClick={handlePrint}
        className="p-button-primary"
      />
      <Button
        label="Close"
        icon="pi pi-times"
        onClick={() => setShowInvoiceModal(false)}
        className="p-button-secondary"
      />
    </div>
  );

  const header = (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex justify-between items-center">
        <div className="w-64">
          <InputText
            placeholder="Search invoices..."
            value={searchKeyword}
            onChange={(e) => setSearchKeyword(e.target.value)}
            className="w-full"
          />
        </div>
        {/* <Button
          label="Create Invoice"
          icon="pi pi-plus"
          onClick={() => setShowCreateModal(true)}
          className="p-button-primary rounded "
        /> */}
      </div>
      <div className="flex gap-4 items-center">
        <Calendar
          value={dateFilters.startDate}
          onChange={(e) =>
            setDateFilters({ ...dateFilters, startDate: e.value })
          }
          placeholder="Start Date"
          showIcon
        />
        <Calendar
          value={dateFilters.endDate}
          onChange={(e) => setDateFilters({ ...dateFilters, endDate: e.value })}
          placeholder="End Date"
          showIcon
        />
        <Button
          label="Filter"
          icon="pi pi-filter"
          onClick={fetchInvoices}
          className="p-button-outlined"
        />
        <Button
          label="Clear"
          icon="pi pi-times"
          onClick={() => {
            setDateFilters({ startDate: null, endDate: null });
            fetchInvoices();
          }}
          className="p-button-outlined p-button-secondary"
        />
      </div>
    </div>
  );

  const filteredInvoices = invoices.filter(
    (invoice) =>
      invoice.invoiceNumber
        ?.toLowerCase()
        .includes(searchKeyword.toLowerCase()) ||
      invoice.client?.name
        ?.toLowerCase()
        .includes(searchKeyword.toLowerCase()) ||
      invoice.status?.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  return (
    <div className="p-4">
      <Toast ref={toast} />
      <ConfirmDialog />

      <Card>
        <DataTable
          value={filteredInvoices}
          header={header}
          loading={loading}
          paginator
          rows={10}
          emptyMessage="No invoices found"
          className="p-datatable-sm"
          stripedRows
          onRowClick={handleRowClick}
          selectionMode="single"
          sortField="issueDate"
          sortOrder={-1}
          responsiveLayout="scroll"
        >
          <Column
            field="invoiceNumber"
            header="Invoice #"
            sortable
            style={{ minWidth: "150px" }}
          />
          <Column
            field="client.name"
            header="Client"
            sortable
            style={{ minWidth: "200px" }}
          />
          <Column
            field="unit.name"
            header="Unit"
            sortable
            style={{ minWidth: "150px" }}
          />
          <Column
            field="totalAmount"
            header="Amount"
            sortable
            body={(rowData) => formatCurrency(rowData.totalAmount)}
            style={{ minWidth: "120px" }}
          />
          <Column
            field="issueDate"
            header="Issue Date"
            sortable
            body={(rowData) => formatDate(rowData.issueDate)}
            style={{ minWidth: "120px" }}
          />
          <Column
            field="dueDate"
            header="Due Date"
            sortable
            body={(rowData) => formatDate(rowData.dueDate)}
            style={{ minWidth: "120px" }}
          />
          <Column
            field="status"
            header="Status"
            body={statusTemplate}
            sortable
            style={{ minWidth: "120px" }}
          />
          <Column
            header="Actions"
            body={actionTemplate}
            style={{ minWidth: "100px" }}
          />
        </DataTable>
      </Card>

      {/* Invoice View/Print Modal */}
      <Dialog
        visible={showInvoiceModal}
        onHide={() => setShowInvoiceModal(false)}
        footer={invoiceModalFooter}
        header="Invoice Details"
        maximizable
        className="w-full md:w-4/5 lg:w-3/4"
        contentClassName="invoice-container"
      >
        {selectedInvoice && (
          <InvoiceTemplate
            invoice={selectedInvoice}
            companyDetails={companyDetails}
          />
        )}
      </Dialog>

      {/* CSS for print mode */}
      <style jsx>{`
        @media print {
          .p-dialog-mask {
            background-color: white !important;
          }
          .p-dialog {
            box-shadow: none !important;
          }
          .p-dialog-header,
          .p-dialog-footer {
            display: none !important;
          }
          .invoice-container {
            padding: 0 !important;
          }
        }
      `}</style>
    </div>
  );
};

export default InvoiceManagement;
