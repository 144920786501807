/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Papa from "papaparse";
import { Link } from "react-router-dom";
import Layout from "../component/layout";
import { updateSpinner } from "../../../features/authentication/authenticationReducer";
import { toastify } from "../../../utils/toast";
import { makeRequest2 } from "../../../utils/makeRequest";
import { importWaterMetersURL } from "../../../utils/urls";

function ImportUnits() {
  const [csvData, setCsvData] = useState([]);
  const [fileName, setFileName] = useState("");
  const [proceed, setProceed] = useState("Download Template");

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/facility/unit_management"}>Unit Management</Link>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Import Units
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-4">
                <Link to={"/facility/unit_management"}>
                  <span>
                    <i className="ti ti-arrow-narrow-left"></i> Back
                  </span>
                </Link>
              </div>
              <div className="container">
                <div className="text-center mt-5">
                  {proceed === "Download Template" && (
                    <>
                      <Link
                        to="/assets/templates/ImportWaterMetersTemplateFile.csv"
                        download
                      >
                        <i
                          className="fas fa-file-csv"
                          style={{ fontSize: 100, color: "#6610f2" }}
                        />
                      </Link>
                      <Link
                        to="/assets/templates/ImportWaterMetersTemplateFile.csv"
                        download
                        style={{ color: "black" }}
                      >
                        <p className="mt-4">
                          <i className="fas fa-cloud-download-alt"></i> Download
                          CSV template file
                        </p>
                      </Link>

                      <p className="mt-5">
                        <Link
                          to="#"
                          onClick={() => {
                            setProceed("Import");
                          }}
                        >
                          <strong>
                            <i className="fas fa-long-arrow-alt-right"></i>{" "}
                            Click Here to Import a File
                          </strong>
                        </Link>
                      </p>
                    </>
                  )}
                </div>
                {proceed !== "Download Template" && (
                  <form
                    action="../assets/json/file-upload.php"
                    className="dropzone"
                  >
                    <div className="fallback">
                      {fileName !== "" && (
                        <>
                          <span>
                            <strong>File Name:</strong> <i>{fileName}</i>{" "}
                          </span>
                          <br />
                          <br />
                          <span>
                            <strong>Data Entries: </strong>
                            <span className="badge bg-light-dark">
                              {csvData.length}
                            </span>{" "}
                          </span>
                          <br />
                          <br />
                        </>
                      )}

                      <input
                        name="file"
                        type="file"
                        onChange={() => {}}
                        accept=".csv"
                        multiple=""
                      />
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default ImportUnits;
