import React, { useState, useEffect } from 'react'; 
import { makeRequest2 } from '../../../../utils/makeRequest'; 
import { toastify } from '../../../../utils/toast'; 
import { deleteContract } from '../../../../utils/urls'; 
import { Button } from 'primereact/button'; 
import { getItem } from '../../../../utils/localStorage'; // Import your local storage utility

const DeleteContract = ({ contractId, status, fetchContracts }) => {
    const [facilityId, setFacilityId] = useState(null);

    // Fetch facilityId when component mounts
    useEffect(() => {
        const fetchFacilityId = async () => {
            const id = await getItem('selectedFacilityId'); // Await the promise here
            setFacilityId(id);
        };
        fetchFacilityId();
    }, []);

    const handleDelete = async () => {
        // Check if the contract is inactive before allowing deletion
        if (status !== 'Inactive') {
            toastify('Contract must be inactive before deleting', 'error');
            return;
        }

        if (!facilityId) {
            toastify('Facility ID is not available', 'error');
            return;
        }

        try {
            // Make the DELETE request to the backend
            const response = await makeRequest2(`${deleteContract}/${facilityId}/${contractId}`, 'DELETE');
            console.log(`Deleting contract at: ${deleteContract}/${facilityId}/${contractId}`); // Log the URL

            if (response.success) {
                toastify('Contract deleted successfully', 'success');
                fetchContracts(); // Refresh the contract list after successful deletion
            } else {
                console.log(response); // Log the entire response for debugging
                toastify(response.error || 'Failed to delete contract', 'error');
            }
        } catch (err) {
            console.error('Error deleting contract:', err);
            toastify(err.message || 'An error occurred while deleting the contract', 'error');
        }
    };

    return (
        <div className="actions">
            <Button 
                 
                className="p-button-rounded p-button-danger mr-2" 
                onClick={handleDelete}
                disabled={!facilityId} 
                icon="ti ti-trash"
            />
        </div>
    );
};

export default DeleteContract;
