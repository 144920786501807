import React from "react";
import { Link } from "react-router-dom";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../../component/layout";
function VisitorRegistration() {
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/facility/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"#"}>Visitor Access Management</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={
                      "/facility/visitor_access_management/visitor_registration"
                    }
                  >
                    Visitor Registration
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12"></div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default VisitorRegistration;
