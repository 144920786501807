import React, { useState, useEffect } from 'react';
import { makeRequest2 } from '../../../../utils/makeRequest';
import { toastify } from '../../../../utils/toast';
import { disable_contract } from '../../../../utils/urls'; // URL for the API endpoint
import { Button } from 'primereact/button';
import { getItem } from '../../../../utils/localStorage'; // Utility to get localStorage items

const DisableContract = ({ contractId, initialStatus, fetchContracts }) => {
    const [status, setStatus] = useState(initialStatus); // Track the current status of the contract
    const [isLoading, setIsLoading] = useState(false); // Loading state for better UX

    // Sync status with the initialStatus prop when it changes
    useEffect(() => {
        setStatus(initialStatus);
    }, [initialStatus]);

    // Function to handle toggling the contract status
    const handleToggleStatus = async () => {
        setIsLoading(true); // Start loading
        try {
            const facilityId = await getItem('selectedFacilityId'); // Fetch the selected facility ID
            if (!facilityId) {
                throw new Error('Facility ID not found. Please select a facility first.');
            }

            const url = `${disable_contract}/${facilityId}/${contractId}`; // Construct the API endpoint

            // Determine the new status and the request body
            const newStatus = status === 'Inactive' ? 'Active' : 'Inactive';
            const requestBody = { status: newStatus }; // Assuming the backend expects a body with the new status

            const response = await makeRequest2(url, 'PATCH', requestBody); // Send the PATCH request with the new status

            if (response.success) {
                // Update the local status state based on the response
                setStatus(newStatus);
                fetchContracts(); // Refresh the contract list after status update
                toastify(`Contract ${newStatus === 'Inactive' ? 'disabled' : 'enabled'} successfully`, 'success');
            } else {
                toastify(response.error || 'Failed to toggle contract status.', 'error');
            }
        } catch (err) {
            console.error('Error toggling contract status:', err);
            toastify(err.message || 'An error occurred while toggling contract status.', 'error');
        } finally {
            setIsLoading(false); // End loading
        }
    };

    return (
        <div className="actions">
            <Button
                
                className={`p-button p-button-rounded ${status === 'Inactive' ? 'p-button-success' : 'p-button-warning'}`}
                onClick={handleToggleStatus}
                disabled={isLoading} 
            
                icon={`ti ${status === 'Inactive' ? 'ti-lock-open' : 'ti-lock'}`}
                
            />
        </div>
    );
};

export default DisableContract;
