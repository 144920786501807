import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../../component/layout";
import ConfirmDialog from "../../component/confirmDialog";
import { getItem } from "../../../../utils/localStorage";
import { toastify } from "../../../../utils/toast";
import { makeRequest2 } from "../../../../utils/makeRequest";
import {
  addLevyType,
  deleteLevyType,
  getLevyTypes,
  getLevies,
  editLevy,
  deleteLevy,
  disableLevy,
} from "../../../../utils/urls";

function LevyManagement() {
  const navigate = useNavigate();

  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);

  const [levies, setLevies] = useState([]);
  const [levyType, setLevyType] = useState("");
  const [levyTypes, setLevyTypes] = useState("");

  const [collectionFrequency, setCollectionFrequency] = useState('');
  const [dueDate, setDueDate] = useState('');

  // const [searchTerm, setSearchTerm] = useState('');

  const [levyId, setLevyId] = useState(null);
  const [levyName, setLevyName] = useState("");
  const [levyAmount, setLevyAmount] = useState("");

  const [selectedRowData, setSelectedRowData] = useState({});
  const [confirmAction, setConfirmAction] = useState(""); // Track the current action ('delete', 'enable', 'disable')
  const [selectedDisableStatus, setSelectedDisableStatus] = useState(false); // Track the current disable status

  const [selectedLevyId, setSelectedLevyId] = useState(null);

  const fetchLevyTypes = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        getLevyTypes + "/" + facilityId,
        "GET",
        {}
      );

      if (response.success) {
        setLevyTypes(response.data);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const LevyTypeSubmit = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      console.log("facilityId", facilityId);
      const levyTypeData = {
        levyType,
      };

      const response = await makeRequest2(
        addLevyType + "/" + facilityId,
        "POST",
        levyTypeData
      );
      console.log("response", response);
      if (response.success) {
        toastify("Levy type added successfully", "success");
        setLevyType("");
        fetchLevyTypes();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const HandleDeleteLevyType = async (levyTypeId) => {
    try {
      const confirmDelete = window.confirm(
        "Are you sure you want to delete this levy type?"
      );
      if (!confirmDelete) {
        return;
      }
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        deleteLevyType + "/" + facilityId + "/" + levyTypeId,
        "DELETE"
      );
      if (response.success) {
        toastify('Levy type deleted successfully', "success");
        fetchLevyTypes();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const fetchLevies = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        getLevies + "/" + facilityId,
        "GET",
        {}
      );

      if (response.success) {
        const leviesWithTypeNames = response.data.map((levy) => ({
          ...levy,
          levyType: levy.levyType.name, // Access the levyType name
        }));
        setLevies(leviesWithTypeNames);
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const actionBodyTemplate = (rowData) => {
    const isDisabled = rowData.disabled; // Check if the row is disabled

    return (
      <div className="actions">
        <Button
          icon="ti ti-edit"
          className="p-button-rounded p-button-info mr-2"
          onClick={() => handleEdit(rowData)}
          tooltip="Edit"
          disabled={isDisabled} // Disable edit button if the row is disabled
        />
        <Button
          icon="ti ti-trash"
          className="p-button-rounded p-button-danger mr-2"
          onClick={() => {
            setSelectedLevyId(rowData._id);
            setConfirmAction("delete"); // Set action to delete
            setConfirmDialogVisible(true);
          }}
          tooltip="Delete"
          disabled={!isDisabled} // Allow deletion only if the row is disabled
        />
        <Button
          icon={`ti ${isDisabled ? "ti-lock-open" : "ti-lock"}`} // Fixed template literal for dynamic icon
          className="p-button-rounded p-button-warning"
          onClick={() => {
            setSelectedLevyId(rowData._id);
            setConfirmAction(isDisabled ? "enable" : "disable"); // Set action to enable/disable
            setSelectedDisableStatus(isDisabled);
            setConfirmDialogVisible(true);
          }}
          tooltip={isDisabled ? "Enable" : "Disable"} // Fixed dynamic tooltip
        />
      </div>
    );
  };

  const handleEdit = (rowData) => {
    setLevyId(rowData._id);
    setLevyName(rowData.levyName);
    setLevyAmount(rowData.amount);
    setSelectedRowData(rowData);
    setEditDialogVisible(true); // Show the edit dialog
  };

  const handleConfirmAction = async () => {
    setConfirmDialogVisible(false);

    if (confirmAction === "delete") {
      // Handle delete logic here
      await handleDelete(selectedLevyId);
    } else if (confirmAction === "enable" || confirmAction === "disable") {
      // Handle enable/disable logic here
      await handleDisable(selectedLevyId, selectedDisableStatus);
    }
  };

  const HandleEditSubmit = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const updatedData = {
        levyName,
        levyAmount,
        collectionFrequency,
        dueDate,
      };

      const response = await makeRequest2(
        `${editLevy}/${facilityId}/${levyId}`,
        "POST",
        updatedData
      );

      if (response.success) {
        toastify("Levy edited successfully", "success");
        setLevyName("");
        setLevyAmount("");
        setCollectionFrequency("");
        setDueDate("");
        setEditDialogVisible(false);
        fetchLevies();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      console.log(err);
      toastify(err.message, "error");
    }
  };

  const handleDelete = async (levyId) => {
    try {
      const facilityId = await getItem("selectedFacilityId");
      const response = await makeRequest2(
        `${deleteLevy}/${facilityId}/${levyId}`,
        "DELETE"
      );

      if (response.success) {
        toastify("Levy deleted successfully", "success");
        fetchLevies();
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const handleDisable = async (levyId, currentStatus) => {
    const action = currentStatus ? "enable" : "disable";

    try {
      const facilityId = await getItem("selectedFacilityId");
      const newDisabledStatus = !currentStatus;

      const response = await makeRequest2(
        `${disableLevy}/${facilityId}/${levyId}`,
        "PATCH",
        { disabled: newDisabledStatus }
      );

      if (response.success) {
        toastify(response.data, "success");
        fetchLevies(); // Refresh the data
      } else {
        throw new Error(response.error);
      }
    } catch (err) {
      toastify(err.message, "error");
    }
  };

  const statusTemplate = (rowData) => {
    const isDisabled = rowData.disabled;

    return (
      <span
        style={{
          fontWeight: "bold",
          color: isDisabled ? "#ff4d4d" : "#4caf50", // Red for Disabled, Green for Enabled
          padding: "5px 10px",
          borderRadius: "15px",
          backgroundColor: isDisabled ? "#ffebeb" : "#e8f5e9", // Light red or green background
        }}
      >
        {isDisabled ? "Disabled" : "Enabled"}
      </span>
    );
  };

  useEffect(() => {
    fetchLevyTypes();
    fetchLevies();
  }, []);

  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/app/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={""}>Levy Management</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body py-0">
          <ul className="nav nav-tabs profile-tabs" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="profile-tab-1"
                data-bs-toggle="tab"
                href="#profile-1"
                role="tab"
                aria-selected="true"
              >
                <i className="ti ti-chart-pie me-2"></i>Levy List
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link"
                id="profile-tab-5"
                data-bs-toggle="tab"
                href="#profile-5"
                role="tab"
                aria-selected="true"
              >
                <i className="ti ti-book me-2"></i>Levy Types
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="tab-content">
        <div
          className="tab-pane show active"
          id="profile-1"
          role="tabpanel"
          aria-labelledby="profile-tab-1"
        >
          <div className="row">
            <div className="col-sm-12">
              <div className="row">
                <div className="col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h5>Levies</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-12 col-xxl-12">
                          <div className="card">
                            <div className="card-body position-relative">
                              <div className="row">
                                <div className="col-md-3 col-xs-12 mb-3">
                                  <input
                                    className="form-control"
                                    placeholder="Search here"
                                  // value={searchTerm}
                                  // onChange={(e) => { handleInputSearch(e.target.value) }}
                                  />
                                </div>

                                <div className="col-md-9 col-xs-12 mb-3">
                                  <div style={{ float: "right" }}>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() =>
                                        navigate(
                                          "/facility/levy_management/levies/add_levy"
                                        )
                                      }
                                    >
                                      Add Levy
                                    </button>
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="dt-responsive table-responsive">
                                    <DataTable
                                      value={levies}
                                      emptyMessage="No levies found."
                                      sortMode="multiple"
                                      paginator
                                      rows={5}
                                      stripedRows
                                      tableStyle={{ minWidth: "50rem" }}
                                      scrollable
                                    >
                                      <Column field="levyName" header="Name" />
                                      <Column field="levyType" header="Type" />
                                      <Column field="amount" header="Amount" />
                                      <Column
                                        field="levyApplicant"
                                        header="Applicant"
                                      />
                                      <Column
                                        field="collectionFrequency"
                                        header="Frequency"
                                      />
                                      <Column
                                        field="invoiceDay"
                                        header="Invoice Day"
                                      />
                                      <Column
                                        field="dueDate"
                                        header="Due Date"
                                      />
                                      <Column
                                        body={statusTemplate}
                                        header="Status"
                                      />
                                      <Column
                                        body={actionBodyTemplate}
                                        header="Actions"
                                      />
                                    </DataTable>
                                    {/* Confirm dialog */}
                                    <ConfirmDialog
                                      visible={isConfirmDialogVisible}
                                      onHide={() =>
                                        setConfirmDialogVisible(false)
                                      }
                                      message={`Are you sure you want to ${confirmAction} this Levy?`}
                                      onConfirm={handleConfirmAction}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="tab-pane fade"
          id="profile-5"
          role="tabpanel"
          aria-labelledby="profile-tab-5"
        >
          <div className="card">
            <div className="tab-content">
              <div
                className="tab-pane active"
                id="setting-1"
                role="tabpanel"
                aria-labelledby="setting-tab-1"
              >
                <div
                  className="tab-pane "
                  id="setting-4"
                  role="tabpanel"
                  aria-labelledby="setting-tab-4"
                >
                  <div className="row p-10 mt-3">
                    <div className="col-lg-6">
                      <div className="card">
                        <div className="card-body">
                          <div className="mt-2">
                            <label>Add New Levy Type:</label>
                            <input
                              className="form-control mt-2"
                              type="text"
                              value={levyType}
                              onChange={(e) => {
                                let value = e.target.value;
                                setLevyType(value);
                              }}
                            />
                          </div>

                          <div style={{ float: "right" }}>
                            <button
                              className="mt-3 btn btn-primary"
                              onClick={LevyTypeSubmit}
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <ul className="list-group">
                        {levyTypes && levyTypes.length > 0 ? (
                          levyTypes.map((levyType, index) => (
                            <li className="list-group-item" key={index}>
                              {index + 1}. {levyType.name}
                              <div style={{ float: "right" }}>
                                <Link
                                  to={"#"}
                                  onClick={() => {
                                    HandleDeleteLevyType(levyType._id);
                                  }}
                                >
                                  <i
                                    className="ti ti-trash f-18"
                                    style={{ color: "red" }}
                                  ></i>
                                </Link>
                              </div>
                            </li>
                          ))
                        ) : (
                          <li className="list-group-item">
                            No levy types found.
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        header={`Edit Levy`}
        width="50vw"
        visible={editDialogVisible}
        onHide={() => {
          if (!editDialogVisible) return;
          setEditDialogVisible(false);
        }}
      >
        <div className="row">
          <div className="col-md-12 mt-3">
            <div className="mb-4">
              <label>Name:</label>
              <input
                className="form-control mt-2"
                type="text"
                placeholder="Levy Name"
                value={levyName}
                onChange={(e) => {
                  const value = e.target.value;
                  setLevyName(value);
                }}
              ></input>
            </div>

            <div className="mb-4">
              <label>Amount:</label>
              <input
                className="form-control mt-2"
                type="text"
                placeholder="Levy Amount"
                value={levyAmount}
                onChange={(e) => {
                  const value = e.target.value;
                  setLevyAmount(value);
                }}
              ></input>
            </div>

            <div className="mb-4">
              <label className="form-label">Collection Frequency</label>
              <select
                className="form-control mt-2"
                value={collectionFrequency}
                onChange={(e) => setCollectionFrequency(e.target.value)}
              >
                <option value="">Select</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Semi-anually">Semi-anually</option>
                <option value="Annually">Annually</option>
              </select>
            </div>

            <div className="mb-4">
              <label className="form-label">Due Date</label>
              <InputText
                type="date"
                className="form-control"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </div>

            <div className="mt-4" style={{ float: "right" }}>
              <button className="btn btn-primary" onClick={HandleEditSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
}

export default LevyManagement;
