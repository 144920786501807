import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { getItem, clearStorage } from "../../../utils/localStorage";
function Nav() {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [userName, setFullname] = useState("");
  const [userRole, setRole] = useState("");

  const HandleLogOut = async () => {
    await clearStorage();
    navigate("/");
  };

  const getCurrentUser = async () => {
    try {
      const currentUserValue = await getItem("APPUSER");

      let currentUser;
      // Check if the value needs to be parsed
      if (typeof currentUserValue === "string") {
        try {
          currentUser = JSON.parse(currentUserValue);
        } catch (err) {
          console.error("Error parsing APPUSER:", err);
        }
      } else {
        currentUser = currentUserValue; // Assume it's already an object
      }

      // Safely access and set user details
      if (currentUser?.user) {
        const fullName = currentUser.user.fullName;
        const role = currentUser.user.role;
        setFullname(fullName);
        setRole(role);
      } else {
        console.error("Invalid user structure:", currentUser);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    getCurrentUser()

  }, [])
  return (
    <nav className="pc-sidebar">
      <div className="navbar-wrapper">
        <div className="m-header">
          <Link to={"/facility/"} className="b-brand text-primary">

            <img
              src="/assets/images/PayServeLogoFinal.png"
              className="img-fluid logo-lg"
              alt="logo"
              style={{ width: 180 }}
            />
            <span className="badge bg-light-success rounded-pill ms-2 theme-version">
              v1.0
            </span>
          </Link>
        </div>
        <div className="navbar-content" style={{ overflowY: 'scroll' }}>
          <div className="card pc-user-card">
            <div className="card-body">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0">
                  <img
                    src="/assets/images/user/avatar-1.jpg"
                    alt="user-image"
                    className="user-avtar wid-45 rounded-circle"
                  />
                </div>
                <div className="flex-grow-1 ms-3 me-2">
                  <h6 className="mb-0">{userName}</h6>
                </div>
                <Link
                  className="btn btn-icon btn-link-secondary avtar"
                  data-bs-toggle="collapse"
                  to="#pc_sidebar_userlink"
                >
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-sort-outline" />
                  </svg>
                </Link>
              </div>
              <div className="collapse pc-user-links" id="pc_sidebar_userlink">
                <div className="pt-3">
                  <Link to={"#!"} onClick={() => { HandleLogOut() }}>
                    <i className="ti ti-power" />
                    <span>Logout</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <ul className="pc-navbar mb-5">
            <li className={`pc-item ${currentPath === '/app/' ? 'active' : ''}`}>
              <Link to={'/app/'} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-logout" />
                  </svg>
                </span>

                <span className="pc-mtext">Home Page</span>
              </Link>
            </li>
            <li
              className={`pc-item ${currentPath === "/facility" ? "active" : ""
                }`}
            >
              <Link to={"/facility/"} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-home" />
                  </svg>
                </span>

                <span className="pc-mtext">Dashboard</span>
              </Link>
            </li>
            <li
              className={`pc-item ${currentPath === "/facility/unit_management" ? "active" : ""
                }`}
            >
              <Link to={"/facility/unit_management"} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-element-plus" />
                  </svg>
                </span>

                <span className="pc-mtext">Unit Management</span>
              </Link>
            </li>

            <li
              className={`pc-item ${currentPath === "/facility/customer_management/customers" ||
                currentPath === "/facility/customer_management/add_new_customer"
                ? "active"
                : ""
                }`}
            >
              <Link
                to={"/facility/customer_management/customers"}
                className="pc-link"
              >
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-profile-2user-outline" />
                  </svg>
                </span>

                <span className="pc-mtext">Member Management</span>


              </Link>
            </li>
            {/* <li className="pc-item pc-caption">
                            <label>Utility Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/utility_management/meter_management/' ? 'active' : ''}`}>
                            <Link to={'/facility/utility_management/meter_management/'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-level" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Meter Management</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/utility_management/billing' ? 'active' : ''}`}>
                            <Link to={'/facility/utility_management/billing'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-story" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Billing</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/utility_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facility/utility_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-presentation-chart" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports</span>
                            </Link>
                        </li>

                        <li className="pc-item pc-caption">
                            <label>Lease Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/lease_management' ? 'active' : ''}`}>
                            <Link to={'/facility/lease_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-level" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Lease Management</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/lease_management/rent_invoicing' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/lease_management/rent_invoicing'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-story" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Levy Invoicing</span>
                            </Link>
                        </li>
                        <li className={`pc-item ${currentPath === '/facilitiy/lease_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/lease_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-status-up" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports</span>
                            </Link>
                        </li> */}

            <li className="pc-item pc-caption">
              <label>Levy Management</label>
              <svg className="pc-icon">
                <use xlinkHref="#custom-presentation-chart" />
              </svg>
            </li>
            <li
              className={`pc-item ${currentPath === "/facility/levy_management" ||
                currentPath === "/facility/levy_management/levies/add_levy"
                ? "active"
                : ""
                }`}
            >
              <Link to={"/facility/levy_management"} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-graph" />
                  </svg>
                </span>

                <span className="pc-mtext">Levy List</span>
              </Link>
            </li>
            <li
              className={`pc-item ${currentPath === "/facility/levy_management/contracts" ||
                currentPath ===
                "/facility/levy_management/contracts/add_contract"
                ? "active"
                : ""
                }`}
            >
              <Link
                to={"/facility/levy_management/contracts"}
                className="pc-link"
              >
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-link" />
                  </svg>
                </span>

                <span className="pc-mtext">Levy Contracts</span>
              </Link>
            </li>
            <li
              className={`pc-item ${currentPath === "/facilitiy/levy_management/levy_invoicing"
                ? "active"
                : ""
                }`}
            >
              <Link
                to={"/facilitiy/levy_management/levy_invoicing"}
                className="pc-link"
              >
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-keyboard" />
                  </svg>
                </span>

                <span className="pc-mtext">Levy Invoicing</span>
              </Link>
            </li>
            <li className={`pc-item ${currentPath === '/facilitiy/levy_management/levy_invoicing' ? 'active' : ''}`}>
              <Link to={"/facility/levy_management/settings"} className="pc-link">
                <span className="pc-micon">
                  <i className="fas fa-cogs"></i> {/* Font Awesome settings icon */}
                </span>
                <span className="pc-mtext">Levy Settings</span>
              </Link>
            </li>
            <li className={`pc-item ${currentPath === '/facilitiy/levy_management/reports' ? 'active' : ''}`}>
              <Link to={'/facilitiy/levy_management/reports'} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-presentation-chart" />
                  </svg>
                </span>

                <span className="pc-mtext">Reports</span>
              </Link>
            </li>
            {/* ++++++++++++++++++++++++++++++++++++++ */}
            {/* ++++++++++++++++++++++++++++++++++++++ */}
            {/* +++++MAINTENANCE MODULE NAVIGATION++++ */}
            {/* ++++++++++++++++++++++++++++++++++++++ */}
            {/* ++++++++++++++++++++++++++++++++++++++ */}
            <li
              className={`pc-item ${currentPath === "/facility/maintenance/work_order_management"
                ? "active"
                : ""
                }`}
            >
              <Link
                to={"/facility/maintenance/work_order_management"}
                className="pc-link"
              >
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-simcard-2" />
                  </svg>
                </span>

                <span className="pc-mtext">Work Order Management</span>
              </Link>
            </li>

            <li
              className={`pc-item ${currentPath === "/facilitiy/maintenance/preventive_maitenance"
                ? "active"
                : ""
                }`}
            >
              <Link
                to={"/facilitiy/maintenance/preventive_maitenance"}
                className="pc-link"
              >
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-calendar-1" />
                  </svg>
                </span>

                <span className="pc-mtext">Preventive Maintenance </span>
              </Link>
            </li>
            <li
              className={`pc-item ${currentPath === "/facilitiy/maintenance/sla" ? "active" : ""
                }`}
            >
              <Link to={"/facilitiy/maintenance/sla"} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-note-1" />
                  </svg>
                </span>

                <span className="pc-mtext">
                SLA Management{" "}
                </span>
              </Link>
            </li>
            <li
              className={`pc-item ${currentPath === "/facilitiy/maintenance/asset" ? "active" : ""
                }`}
            >
              <Link to={"/facilitiy/maintenance/asset"} className="pc-link">
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-layer" />
                  </svg>
                </span>

                <span className="pc-mtext">Asset Management </span>
              </Link>
            </li>
            <li
              className={`pc-item ${currentPath === "/facilitiy/maintenance/stock_and_spare_parts"
                ? "active"
                : ""
                }`}
            >
              <Link
                to={"/facilitiy/maintenance/stock_and_spare_parts"}
                className="pc-link"
              >
                <span className="pc-micon">
                  <svg className="pc-icon">
                    <use xlinkHref="#custom-clipboard" />
                  </svg>
                </span>

                <span className="pc-mtext">Stock & Spare Parts </span>
              </Link>
            </li>
            <li className="pc-item pc-caption">
              <label>Value Added Services</label>
              <svg className="pc-icon">
                <use xlinkHref="#custom-presentation-chart" />
              </svg>
            </li>
            <li className={`pc-item ${currentPath === '/facility/value_added_services/service_list' ? 'active' : ''}`}>
              <Link to={'/facility/value_added_services/service_list'} className="pc-link">
                <span className="pc-micon">
                  <i className="fas fa-briefcase"></i> {/* Font Awesome icon for services */}
                </span>
                <span className="pc-mtext">Value Added Services</span>
              </Link>
            </li>

            {/* <li className="pc-item pc-caption">
                            <label>Expense Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/expense_management' ? 'active' : ''}`}>
                            <Link to={'/facility/expense_management'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-layer" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Expense Management</span>
                            </Link>
                        </li>

                        <li className={`pc-item ${currentPath === '/facilitiy/expense_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/expense_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-shapes" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports</span>
                            </Link>
                        </li>
                        
                        <li className="pc-item pc-caption">
                            <label>Maintenance Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        
                        <li className={`pc-item ${currentPath === '/facilitiy/maintenance_management/compliance_and_safety' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/maintenance_management/compliance_and_safety'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-shield" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Compliance & Safety </span>
                            </Link>
                        </li>
                        <li className="pc-item pc-caption">
                            <label>Handover Management</label>
                            <svg className="pc-icon">
                                <use xlinkHref="#custom-presentation-chart" />
                            </svg>
                        </li>
                        <li className={`pc-item ${currentPath === '/facility/handover_management/pre_handover_inspection' ? 'active' : ''}`}>
                            <Link to={'/facility/handover_management/pre_handover_inspection'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-mask" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Pre Handover Inspection</span>
                            </Link>
                        </li>


                        <li className={`pc-item ${currentPath === '/facilitiy/handover_management/post_handover_inspection' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/handover_management/post_handover_inspection'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-crop" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Post-Handover Inspection</span>
                            </Link>
                        </li>


                        <li className={`pc-item ${currentPath === '/facilitiy/handover_management/reports' ? 'active' : ''}`}>
                            <Link to={'/facilitiy/handover_management/reports'} className="pc-link">
                                <span className="pc-micon">
                                    <svg className="pc-icon">
                                        <use xlinkHref="#custom-mouse-circle" />
                                    </svg>
                                </span>

                                <span className="pc-mtext">Reports </span>
                            </Link>
                        </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
}
export default Nav;
