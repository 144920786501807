import { createBrowserRouter } from "react-router-dom";
// // import { BrowserRouter, Routes, Route } from "react-router-dom";

// PAGES

import Login from "../components/authentication/login";
import ForgotPassword from "../components/authentication/forgotPassword";
import CheckEmail from "../components/authentication/checkEmail";
import CodeVerification from "../components/authentication/codeVerification";
import ResetPassword from "../components/authentication/resetPassword";
import Error404Page from "../components/error/Error404Page";

// APP
import Dashboard from "../components/app/dashboard/dashboard";
import Facilities from "../components/app/facility_management/facilities";
import AddNewFacility from "../components/app/facility_management/add_new_facility";
import Customers from "../components/app/customer_management/customer_management";
import AddNewCustomers from "../components/app/customer_management/add_new_customer";
import ImportCustomers from "../components/app/customer_management/import_customers";

import CampaignManagement from "../components/app/compaign_management/campaign_management";
import TicketManagement from "../components/app/ticket_management/ticket_management";
import SurveyManagement from "../components/app/survey_management/survey_management";
import Setting from "../components/app/settings/setting";

// FACILITY
import DashboardFacility from '../components/facility/dashboard/dashboard'
import Settings from '../components/facility/levy_management/settings/settings'
import UnitManagement from '../components/facility/unit_management/unit_management'
import ImportUnits from '../components/facility/unit_management/import_units'
import AddNewUnit from '../components/facility/unit_management/add_new_unit'
import MeterManagement from '../components/facility/utility_management/meter_management/meters'
import BillingManagement from '../components/facility/utility_management/billing_management/billing'
import UtilityReports from '../components/facility/utility_management/reports/report'
import ViewWaterMeter from '../components/facility/utility_management/meter_management/view_water_meter'
import ViewPowerMeter from "../components/facility/utility_management/meter_management/view_power_meter";
import ViewWaterCustomerAccount from '../components/facility/utility_management/billing_management/view_water_customer_account';
import ViewPowerCustomerAccount from "../components/facility/utility_management/billing_management/view_power_customer_account";
import LeaseManagement from "../components/facility/lease_management/lease_management/lease_management";
import RentInvoicingManagement from "../components/facility/lease_management/rent_invoicing/rent_invoicing";
import CustomerManagement from "../components/facility/customer_management/customers";
import AddCustomer from "../components/facility/customer_management/add_customer";
import LeaseReports from "../components/facility/lease_management/reports/reports";
import LevyManagement from "../components/facility/levy_management/levies/levy_list";
import AddLevy from "../components/facility/levy_management/levies/add_levy";
import LevyContract from "../components/facility/levy_management/contracts/levy_contract";
import AddContract from "../components/facility/levy_management/contracts/add_contract";
import LevyInvoicingManagement from "../components/facility/levy_management/Invoicing/levy_invoicing";
import LevyReports from "../components/facility/levy_management/reports/reports";
import ExpenseManagement from "../components/facility/expense_management/expense_management/expense_management";
import ExpenseReports from "../components/facility/expense_management/reports/reports";
import VisitorRegistration from "../components/facility/visitor_access_management/visitor_registration/visitor_registration";
import GuardRegistration from "../components/facility/visitor_access_management/guard_registration/guard_registration";
import EntriesAndExists from "../components/facility/visitor_access_management/entires_and_exisits/entries_and_exists";
import DigitalLogs from "../components/facility/visitor_access_management/digital_logs/digital_logs";
import AccessControl from "../components/facility/visitor_access_management/access_control/access_control";
import ServiceManagement from "../components/facility/value_added_services/service_list";
import maintenanceRouter from "../components/facility/maintenance_management/routes";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/forgot_password",
    element: <ForgotPassword />,
  },
  {
    path: "/check_email",
    element: <CheckEmail />,
  },
  {
    path: "/code_verification",
    element: <CodeVerification />,
  },
  {
    path: "/reset_password/:userId",
    element: <ResetPassword />,
  },
  {
    path: "/app/",
    element: <Dashboard />,
  },
  {
    path: "/app/facilities",
    element: <Facilities />,
  },
  {
    path: "/app/add_new_facility",
    element: <AddNewFacility />,
  },
  {
    path: "/app/campaign_management",
    element: <CampaignManagement />,
  },
  {
    path: "/app/customer_management",
    element: <Customers />,
  },
  {
    path: "/app/customer_management/add_new_customer",
    element: <AddNewCustomers />,
  },
  {
    path: "/app/customer_management/import_customers",
    element: <ImportCustomers />,
  },
  {
    path: "/app/ticket_management",
    element: <TicketManagement />,
  },

  {
    path: "/app/survey_management",
    element: <SurveyManagement />
  },
  {
    path: "/app/settings",
    element: <Setting />,
  },
  {
    path: "/facility/levy_management/settings",
    element: <Settings />,
  },
  {
    path: "/facility/",
    element: <DashboardFacility />
  },
  {
    path: "/facility/unit_management",
    element: <UnitManagement />
  },
  {
    path: "/facility/unit_management/add_new_unit",
    element: <AddNewUnit />
  },
  {
    path: "/facility/unit_management/import_units",
    element: <ImportUnits />
  },
  {
    path: "/facility/customer_management/customers",
    element: <CustomerManagement />
  },
  {
    path: "/facility/customer_management/add_new_customer",
    element: <AddCustomer />
  },
  {
    path: "/facility/utility_management/meter_management/",
    element: <MeterManagement />
  },
  {
    path: "/facility/utility_management/meter_management/view_water_meter",
    element: <ViewWaterMeter />
  },
  {
    path: "/facility/utility_management/meter_management/view_power_meter",
    element: <ViewPowerMeter />
  },
  {
    path: "/facility/utility_management/meter_management/view_water_customer_account",
    element: <ViewWaterCustomerAccount />
  },
  {
    path: "/facility/utility_management/meter_management/view_power_customer_account",
    element: <ViewPowerCustomerAccount />
  },
  {
    path: "/facility/utility_management/billing",
    element: <BillingManagement />
  },
  {
    path: '/facility/utility_management/reports',
    element: <UtilityReports />
  },
  {
    path: "/facility/lease_management/",
    element: <LeaseManagement />
  },
  {
    path: "/facilitiy/lease_management/rent_invoicing",
    element: <RentInvoicingManagement />
  },
  {
    path: "/facilitiy/lease_management/reports",
    element: <LeaseReports />
  },
  {
    path: "/facility/levy_management/",
    element: <LevyManagement />
  },
  {
    path: "/facility/levy_management/levies/add_levy",
    element: <AddLevy />
  },
  {
    path: "/facility/levy_management/contracts",
    element: <LevyContract />
  },
  {
    path: "/facility/levy_management/contracts/add_contract",
    element: <AddContract />
  },
  {
    path: "/facilitiy/levy_management/levy_invoicing",
    element: <LevyInvoicingManagement />
  },
  {
    path: "/facilitiy/levy_management/reports",
    element: <LevyReports />
  },
  {
    path: "/facility/expense_management",
    element: <ExpenseManagement />
  },
  {
    path: "facilitiy/expense_management/reports",
    element: <ExpenseReports />
  },
  {
    path: "facilitiy/visitor_management/visitor_registration",
    element: <VisitorRegistration />
  },
  {
    path: "facilitiy/visitor_management/guard_registration",
    element: <GuardRegistration />
  },
  {
    path: "facilitiy/visitor_management/digital_logs",
    element: <DigitalLogs />
  },
  {
    path: "facilitiy/visitor_management/entries_and_exits",
    element: <EntriesAndExists />
  },
  {
    path: "facilitiy/visitor_management/access_control",
    element: <AccessControl />
  },
  {
    path: "/facility/value_added_services/service_list",
    element: <ServiceManagement />
  },


  {
    path: "*",
    element: <Error404Page />,
  },
  ...maintenanceRouter,
]);
