export const backend_url = "https://api.sandbox.payserve.co.ke";
// export const backend_url = "http://localhost:3040";
export const checkEmailAndPasswordURL = "/api/auth/check_email_and_password";
export const loginURL = "/api/auth/login";
export const forgotPasswordURL = "/api/auth/forgot_password";
export const resetPasswordURL = "/api/auth/reset_password";
export const codeVerificationURL = "/api/auth/otp";
export const resendCodeURL = "/api/auth/resend_code";
export const verifyOTPURL = "/api/auth/verify_otp";
export const checkTokenExpirationURL = "/api/auth/check_jwt_expiration";
export const addWaterConcentratorURL = "/api/core/water/add_concentrator";
export const getWaterConcentratorsURL = "/api/core/water/get_concentrators";
export const importWaterConcentratorsURL =
  "/api/core/water/import_concentrators";
export const addWaterMeterURL = "/api/core/water/add_water_meter";
export const getWaterMetersURL = "/api/core/water/get_water_meters";
export const importWaterMetersURL = "/api/core/water/import_water_meters";
export const confirmCompanyNameURL =
  "/api/core/company_management/confirm_company_name";
export const getCompaniesURL = "/api/core/company_management/get_companies";
export const addCompanyURL = "/api/core/company_management/add_company";
export const addExistingCompanyToUserURL =
  "/api/core/user_management/add_a_existing_company_to_user";
export const addNewCompanyToUserURL =
  "/api/core/user_management/add_a_new_company_to_user";
export const getCompanyUsersURL = "/api/core/user_management/get_company_users";
export const confirmUserEmailURL =
  "/api/core/user_management/confirm_user_email";
export const getSitesURL = "/api/core/site_management/get_sites";
export const addSiteURL = "/api/core/site_management/add_site";
export const getProjectsURL = "/api/core/project_management/get_projects";
export const getTransactionsURL = "";
export const getWaterMeterAccountURL = "";
export const getAPIMetricsURL = "/api/core/api_management/get_metrics";
export const getListOfFacilities =
  "/api/app/user_management/get_list_of_facilities";
export const getFacilityUnits = "/api/app/unit_management/get_facility_units";
export const getFacilityURL = "/api/app/unit_management/get_facility_units";
export const getFacilities = "/api/app/unit_management/get_facilities";
export const getUnitsForFacility =
  "/api/app/facility_management/get_units_for_facility";
export const AddNewCustomerURL =
  "/api/app/customer_management/add_new_customer";
export const getCustomersURL = "/api/app/customer_management/get_customers";

export const addLevyType = "/api/app/levy_management/add_levy_type";
export const getLevyTypes = "/api/app/levy_management/get_levy_types";
export const deleteLevyType = "/api/app/levy_management/delete_levy_type";
export const editLevyType = "/api/app/levy_management/edit_levy_type";

export const addLevy = "/api/app/levy_management/add_levy";
export const editLevy = "/api/app/levy_management/edit_levy";
export const deleteLevy = "/api/app/levy_management/delete_levy";
export const disableLevy = "/api/app/levy_management/disable_levy";
export const getLevies = "/api/app/levy_management/get_levies";

export const addContract = "/api/app/levy_management/add_contract";
export const getContracts = "/api/app/levy_management/get_contracts";
export const editContract = "/api/app/levy_management/edit_contract";
export const disable_contract = "/api/app/levy_management/disable_contract";
export const deleteContract = "/api/app/levy_management/delete_contract";

export const addReminder = "/api/app/levy_management/reminders/add_reminder";
export const addPenalty = "/api/app/levy_management/penalty/add_penalty";
export const facilityReminders =
  "/api/app/levy_management/get_facility_reminders";
export const facilityPenalties =
  "/api/app/levy_management/get_facility_penalties";
export const getLeviesURL = "/api/app/levy_management/get_levies";
export const getServices = "/api/app/vas_management/get_maintenance_services";
export const addNewService = "/api/app/vas_management/add_new_service";
