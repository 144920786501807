import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../component/layout";
import { getItem } from "../../../utils/localStorage";
import { getFacilityUnits } from "../../../utils/urls";
import { makeRequest2 } from "../../../utils/makeRequest";
import { toastify } from "../../../utils/toast";
import { setItem } from "localforage";

function Dashboard() {
  const [occupiedUnits, setOccupiedUnits] = useState(0);
  const [notOccupiedUnits, setUnoccupiedUnits] = useState(0);

  const fetchUnitsCount = async () => {
    try {
      const facilityId = await getItem("selectedFacilityId");

      const response = await makeRequest2(
        `${getFacilityUnits}/${facilityId}`,
        "GET",
        {}
      );
      console.log(response);

      if (response.success) {
        const { occupiedUnits, notOccupiedUnits } = response.data;
        setOccupiedUnits(occupiedUnits.length);
        setUnoccupiedUnits(notOccupiedUnits.length);
      } else {
        throw new Error("Failed to fetch units count");
      }
    } catch (err) {
      console.error(err.message);
      toastify(err.message, "error");
    }
  };

  useEffect(() => {
    fetchUnitsCount();
  }, []);

  return (
    <Layout>
      <div className="row">
        {/* <div className="col-md-8">
          <div className="card">
            <div className="card-header">
              <div className="flex-grow-1 ms-3">
                <h6 className="mb-0">Revenue</h6>
              </div>

            </div>
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between" >

                <select className="form-select rounded-3 form-select-sm w-auto">
                  <option selected>Current Month</option>
                  <option>Last Month</option>
                  <option >Custom</option>
                </select>
              </div>
              <div className="row mt-3">
                <div className="col-md-6 col-xl-6">
                  <div className="card social-widget-card bg-primary">
                    <div className="card-body">
                      <h3 className="text-white m-0">KES ....</h3>
                      <span className="m-t-10">Collected Revenue</span>
                      <i className="ti ti-brand-google-analytics  f-36"></i>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xl-6">
                  <div className="card social-widget-card bg-primary">
                    <div className="card-body">
                      <h3 className="text-white m-0">KES ....</h3>
                      <span className="m-t-10">Uncollected Revenue</span>
                      <i className="ti ti-chart-infographic f36"></i>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div> */}
        {/* <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between mb-3">
                <h5 className="mb-0">Today's Consumption</h5>

              </div>
              <div className="row text-center">
                <div className="col-md-6">
                <h5 className="mb-1" >190 m³</h5>
                <p className="text-muted mb-0"><span className="badge bg-light-primary">Water</span></p>
                </div>
                <div className="col-md-6">
                <h5 className="mb-1" >250 Kwh</h5>
                <p className="text-muted mb-0"><span className="badge bg-light-danger">Power</span></p>
                </div>
                
              </div>

            </div>
            <div className="table-body card-body pt-0">
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Last Period</th>
                      <th className="text-end">Utility</th>
                      <th className="text-end">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center text-muted">
                          <span className="text-truncate w-100">2024-07-20</span>
                        </div>
                      </td>
                      <td className="text-end"><span className="badge bg-light-primary">Water</span></td>
                      <td className="text-end f-w-600"><span className="text-success">100 m³</span></td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center text-muted">
                          <span className="text-truncate w-100">2024-07-20</span>
                        </div>
                      </td>
                      <td className="text-end"><span className="badge bg-light-danger">Power</span></td>
                      <td className="text-end f-w-600"><span className="text-success">196 Kwh</span></td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header">
              <div className="flex-grow-1 ms-3">
                <h6 className="mb-0">Units</h6>
              </div>
            </div>
            <div className="card-body">
              <div className="row mt-3">
                <div className="col-md-6 col-xxl-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h3 className="mb-1">{occupiedUnits}</h3>
                          <p className="text-muted mb-0">Occupied Units</p>
                        </div>
                        <div className="col-4 text-end">
                          <i className="ti ti-lock text-primary f-36"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <h3 className="mb-1">{notOccupiedUnits}</h3>
                          <p className="text-muted mb-0">Unoccupied Units</p>
                        </div>
                        <div className="col-4 text-end">
                          <i className="ti ti-lock-open text-danger f-36"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default Dashboard;
