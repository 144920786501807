import React from "react";
import { Link } from "react-router-dom";
import "primereact/resources/themes/lara-light-blue/theme.css";
import Layout from "../component/layout";
function Setting() {
  // const header = (
  //   <div className="flex align-items-center justify-content-end gap-2">
  //     <Button
  //       type="button"
  //       label="CSV"
  //       rounded
  //       onClick={() => {}}
  //       data-pr-tooltip="CSV"
  //     />
  //     <Button
  //       type="button"
  //       label="XLS"
  //       severity="success"
  //       rounded
  //       onClick={() => {}}
  //       data-pr-tooltip="XLS"
  //     />
  //     <Button
  //       type="button"
  //       label="PDF"
  //       severity="warning"
  //       rounded
  //       onClick={() => {}}
  //       data-pr-tooltip="PDF"
  //     />
  //   </div>
  // );
  return (
    <Layout>
      <div className="page-header">
        <div className="page-block">
          <div className="row align-items-center">
            <div className="col-md-12">
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={"/resident/"}>Dashboard</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={"/resident/settings"}>Settings</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="card">
                        <div className="card-body py-0">
                          <ul
                            className="nav nav-tabs profile-tabs"
                            id="myTab"
                            role="tablist"
                          >
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                id="profile-tab-1"
                                data-bs-toggle="tab"
                                href="#profile-1"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-user me-2"></i>Profile
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-2"
                                data-bs-toggle="tab"
                                href="#profile-2"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-file-text me-2"></i>Personal
                                Details
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-4"
                                data-bs-toggle="tab"
                                href="#profile-4"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-lock me-2"></i>Change
                                Password
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-5"
                                data-bs-toggle="tab"
                                href="#profile-5"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-users me-2"></i>Users
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                id="profile-tab-5"
                                data-bs-toggle="tab"
                                href="#profile-5"
                                role="tab"
                                aria-selected="true"
                              >
                                <i className="ti ti-bell me-2"></i>Email / SMS
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane show active"
                          id="profile-1"
                          role="tabpanel"
                          aria-labelledby="profile-tab-1"
                        >
                          <div className="row">
                            <div className="col-lg-4 col-xxl-3">
                              <div className="card">
                                <div className="card-body position-relative">
                                  <div className="text-center mt-3">
                                    <div className="chat-avtar d-inline-flex mx-auto">
                                      <img
                                        className="rounded-circle img-fluid wid-70"
                                        src="../assets/images/user/avatar-5.jpg"
                                        alt="User avatar"
                                      />
                                    </div>
                                    <h5 className="mb-0">Jane Wanjiru</h5>
                                    <p className="text-muted text-sm">
                                      Resident
                                    </p>
                                    <hr className="my-3 border border-secondary-subtle" />
                                    <div className="row g-3">
                                      <div className="col-4"></div>
                                      <div className="col-4 border border-top-0 border-bottom-0">
                                        <h5 className="mb-0">2</h5>
                                        <small className="text-muted">
                                          Units
                                        </small>
                                      </div>
                                      <div className="col-4"></div>
                                    </div>
                                    <hr className="my-3 border border-secondary-subtle" />
                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                      <i className="ti ti-mail me-2"></i>
                                      <p className="mb-0">janedoe@gmail.com</p>
                                    </div>
                                    <div className="d-inline-flex align-items-center justify-content-start w-100 mb-3">
                                      <i className="ti ti-phone me-2"></i>
                                      <p className="mb-0">(+254) 799010210</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-2"
                          role="tabpanel"
                          aria-labelledby="profile-tab-2"
                        >
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Personal Information</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          First Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value="Jane"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Last Name
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value="Wanjiru"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Contact Information</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Contact Phone
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value="(+254) 799010210"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Email{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value="jane@gmail.com"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 text-end btn-page">
                              <div className="btn btn-outline-secondary">
                                Cancel
                              </div>
                              <div className="btn btn-primary">
                                Update Profile
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-3"
                          role="tabpanel"
                          aria-labelledby="profile-tab-3"
                        >
                          <div className="row">
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h5>General Settings</h5>
                                </div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Username{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value="Ashoka_Tano_16"
                                        />
                                        <small className="form-text text-muted">
                                          Your Profile URL:
                                          https://pc.com/Ashoka_Tano_16
                                        </small>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Account Email{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value="demo@sample.com"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Language
                                        </label>
                                        <select className="form-control">
                                          <option>Washington</option>
                                          <option>India</option>
                                          <option>Africa</option>
                                          <option>New York</option>
                                          <option>Malaysia</option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="mb-3">
                                        <label className="form-label">
                                          Sign in Using
                                        </label>
                                        <select className="form-control">
                                          <option>Password</option>
                                          <option>Face Recognition</option>
                                          <option>Thumb Impression</option>
                                          <option>Key</option>
                                          <option>Pin</option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Advance Settings</h5>
                                </div>
                                <div className="card-body">
                                  <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-0 pt-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <p className="mb-1">
                                            Secure Browsing
                                          </p>
                                          <p className="text-muted text-sm mb-0">
                                            Browsing Securely ( https ) when
                                            it's necessary
                                          </p>
                                        </div>
                                        <div className="form-check form-switch p-0">
                                          <input
                                            className="form-check-input h4 position-relative m-0"
                                            type="checkbox"
                                            role="switch"
                                            checked=""
                                          />
                                        </div>
                                      </div>
                                    </li>
                                    <li className="list-group-item px-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <p className="mb-1">
                                            Login Notifications
                                          </p>
                                          <p className="text-muted text-sm mb-0">
                                            Notify when login attempted from
                                            other place
                                          </p>
                                        </div>
                                        <div className="form-check form-switch p-0">
                                          <input
                                            className="form-check-input h4 position-relative m-0"
                                            type="checkbox"
                                            role="switch"
                                            checked=""
                                          />
                                        </div>
                                      </div>
                                    </li>
                                    <li className="list-group-item px-0 pb-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div>
                                          <p className="mb-1">
                                            Login Approvals
                                          </p>
                                          <p className="text-muted text-sm mb-0">
                                            Approvals is not required when login
                                            from unrecognized devices.
                                          </p>
                                        </div>
                                        <div className="form-check form-switch p-0">
                                          <input
                                            className="form-check-input h4 position-relative m-0"
                                            type="checkbox"
                                            role="switch"
                                            checked=""
                                          />
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Recognized Devices</h5>
                                </div>
                                <div className="card-body">
                                  <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-0 pt-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                          <p className="mb-2">Celt Desktop</p>
                                          <p className="mb-0 text-muted">
                                            4351 Deans Lane
                                          </p>
                                        </div>
                                        <div className="">
                                          <div className="text-success d-inline-block me-2">
                                            <i className="fas fa-circle f-10 me-2"></i>
                                            Current Active
                                          </div>
                                          <a href="#!" className="text-danger">
                                            <i className="feather icon-x-circle"></i>
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="list-group-item px-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                          <p className="mb-2">Imco Tablet</p>
                                          <p className="mb-0 text-muted">
                                            4185 Michigan Avenue
                                          </p>
                                        </div>
                                        <div className="">
                                          <div className="text-muted d-inline-block me-2">
                                            <i className="fas fa-circle f-10 me-2"></i>
                                            5 days ago
                                          </div>
                                          <a href="#!" className="text-danger">
                                            <i className="feather icon-x-circle"></i>
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                    <li className="list-group-item px-0 pb-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                          <p className="mb-2">Albs Mobile</p>
                                          <p className="mb-0 text-muted">
                                            3462 Fairfax Drive
                                          </p>
                                        </div>
                                        <div className="">
                                          <div className="text-muted d-inline-block me-2">
                                            <i className="fas fa-circle f-10 me-2"></i>
                                            1 month ago
                                          </div>
                                          <a href="#!" className="text-danger">
                                            <i className="feather icon-x-circle"></i>
                                          </a>
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="card">
                                <div className="card-header">
                                  <h5>Active Sessions</h5>
                                </div>
                                <div className="card-body">
                                  <ul className="list-group list-group-flush">
                                    <li className="list-group-item px-0 pt-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                          <p className="mb-2">Celt Desktop</p>
                                          <p className="mb-0 text-muted">
                                            4351 Deans Lane
                                          </p>
                                        </div>
                                        <button className="btn btn-link-danger">
                                          Logout
                                        </button>
                                      </div>
                                    </li>
                                    <li className="list-group-item px-0 pb-0">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="me-2">
                                          <p className="mb-2">Moon Tablet</p>
                                          <p className="mb-0 text-muted">
                                            4185 Michigan Avenue
                                          </p>
                                        </div>
                                        <button className="btn btn-link-danger">
                                          Logout
                                        </button>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-12 text-end">
                              <button className="btn btn-outline-dark ms-2">
                                Clear
                              </button>
                              <button className="btn btn-primary">
                                Update Profile
                              </button>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane"
                          id="profile-4"
                          role="tabpanel"
                          aria-labelledby="profile-tab-4"
                        >
                          <div className="card">
                            <div className="card-header">
                              <h5>Change Password</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Old Password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      New Password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Confirm Password
                                    </label>
                                    <input
                                      type="password"
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <h5>New password must contain:</h5>
                                  <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                      <i className="ti ti-circle-check text-success f-16 me-2"></i>{" "}
                                      At least 8 characters
                                    </li>
                                    <li className="list-group-item">
                                      <i className="ti ti-circle-check text-success f-16 me-2"></i>{" "}
                                      At least 1 lower letter (a-z)
                                    </li>
                                    <li className="list-group-item">
                                      <i className="ti ti-circle-check text-success f-16 me-2"></i>{" "}
                                      At least 1 uppercase letter(A-Z)
                                    </li>
                                    <li className="list-group-item">
                                      <i className="ti ti-circle-check text-success f-16 me-2"></i>{" "}
                                      At least 1 number (0-9)
                                    </li>
                                    <li className="list-group-item">
                                      <i className="ti ti-circle-check text-success f-16 me-2"></i>{" "}
                                      At least 1 special characters
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="card-footer text-end btn-page">
                              <div className="btn btn-outline-secondary">
                                Cancel
                              </div>
                              <div className="btn btn-primary">
                                Update Profile
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Setting;
