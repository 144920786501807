import React from 'react';
import Nav from './nav'
import Header from './header'
import Footer from './footer'

function Layout({ children }) {
 
  return (
    <>
      <Nav />
      <Header />
      <div className="pc-container">
        <div className="pc-content">
          {children}
        </div>
      </div>

      <Footer />
    </>
  );
}
export default Layout;