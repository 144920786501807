import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primereact/resources/themes/lara-light-blue/theme.css";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { ConfirmDialog } from "primereact/confirmdialog";
import Layout from "../../component/layout";
import { makeRequest2 } from "../../../../utils/makeRequest";
import { useFacilityStore } from "../../../../app/z-store/store";

const ServiceVendor = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const selectedFacilityId = useFacilityStore((state) => state.facilityId);
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    address: "",
    serviceType: "",
  });
  const toast = useRef(null);

  useEffect(() => {
    if (selectedFacilityId) {
      fetchVendors(selectedFacilityId);
    }
  }, [selectedFacilityId]);

  const fetchVendors = async (facilityId) => {
    try {
      setLoading(true);
      const response = await makeRequest2(
        `/api/service-vendors/${facilityId}`,
        "GET",
        null
      );

      if (response.success) {
        setVendors(response.data || []);
        setError(null);
      } else {
        setError(response.error);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error,
        });
      }
    } catch (err) {
      setError("Failed to fetch vendors");
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: err.message || "Failed to fetch vendors",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleModalOpen = (vendor = null) => {
    if (vendor) {
      setSelectedVendor(vendor);
      setFormData({
        name: vendor.name || "",
        contact: vendor.contact || "",
        address: vendor.address || "",
        serviceType: vendor.serviceType || "",
      });
    } else {
      setSelectedVendor(null);
      setFormData({
        name: "",
        contact: "",
        address: "",
        serviceType: "",
      });
    }
    setShowModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name || !formData.contact) {
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: "Name and Contact are required",
      });
      return;
    }

    setLoading(true);
    try {
      let response;
      if (selectedVendor) {
        response = await makeRequest2(
          `/api/service-vendors/${selectedFacilityId}/${selectedVendor._id}`,
          "PUT",
          formData
        );
      } else {
        response = await makeRequest2(
          `/api/service-vendors/${selectedFacilityId}`,
          "POST",
          formData
        );
      }

      if (response.success) {
        toast.current.show({
          severity: "success",
          summary: "Success",
          detail: selectedVendor
            ? "Vendor updated successfully"
            : "Vendor added successfully",
        });
        fetchVendors(selectedFacilityId);
        setShowModal(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: response.error,
        });
      }
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "An error occurred",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (vendorId) => {
    confirmDialog({
      message: "Are you sure you want to delete this vendor?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: async () => {
        try {
          const response = await makeRequest2(
            `/api/service-vendors/${selectedFacilityId}/${vendorId}`,
            "DELETE",
            null
          );

          if (response.success) {
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "Vendor deleted successfully",
            });
            fetchVendors(selectedFacilityId);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: response.error,
            });
          }
        } catch (err) {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Failed to delete vendor",
          });
        }
      },
    });
  };

  const actionTemplate = (rowData) => {
    return (
      <div>
        <button
          className="btn btn-sm btn-primary me-2"
          onClick={() => handleModalOpen(rowData)}
        >
          <i className="ti ti-edit"></i>
        </button>
        <button
          className="btn btn-sm btn-danger"
          onClick={() => handleDelete(rowData._id)}
        >
          <i className="ti ti-trash"></i>
        </button>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <input
          type="text"
          className="form-control w-25"
          placeholder="Search vendors..."
          value={searchKeyword}
          onChange={(e) => setSearchKeyword(e.target.value)}
        />
        <button className="btn btn-primary" onClick={() => handleModalOpen()}>
          <i className="ti ti-plus me-1"></i>Add New Vendor
        </button>
      </div>
    );
  };

  return (
    <Layout>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">Service Vendors</div>
            <div className="card-body">
              {renderHeader()}
              <DataTable
                value={vendors}
                loading={loading}
                paginator
                rows={10}
                className="mt-3"
                emptyMessage="No vendors found"
              >
                <Column field="name" header="Name" sortable />
                <Column field="contact" header="Contact" sortable />
                <Column field="address" header="Address" sortable />
                <Column field="serviceType" header="Service Type" sortable />
                <Column body={actionTemplate} header="Actions" />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ServiceVendor;
